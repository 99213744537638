.cardHeader {
    cursor: pointer;
}

.cardHeader button {
    float: right;
}

.actions {
    text-align: center;
    width: 15%;
}

.actionButton {
    margin-right: 2%;
}

.hide {
    display: none;
}

.notifications {
    /* padding-right: 0 !important;  */
     /* overflow-y: scroll; */
}

.notifications ul {
    list-style-type: none;
    margin-top: 6px;
    /* overflow-y: scroll; */
    /* height: 200px; */
    padding: 4px 20px;
    /* margin-right: 48px; */
}

.notifications li {
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.1);
    background-color: #f0efef;
    font-size: 18px;
    width: 100%;
    padding: 16px 50px;
    margin: 8px 0;
    text-align: justify;
    border-radius: 35px;
}