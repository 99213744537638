/*font-size ainda não definidas*/
/* ----------------------------*/

.detail {
    padding: 4px;
    font-size: 16px;
}

.tableItens {
    border: 1px solid black;
    width: 100%;
}

.corte {
    border: 1px dashed black;
    margin-top: 15%;
}

.assinaturas {
    margin-top: 100px;
    width: 60%;
}
.assinaturas hr{
    align-items: initial;
}

.assinaturas p{
    font-size: 12px;
    margin-top: -10px;
    text-align: center;
}

.signature{
    margin-top: 44px;
}

.data{
    font-size: 16px;
    position: flex;
    margin-top: 13px;
}

.box{
    margin-top: 15px;
    border: 1px solid black;
    border-radius: 14px;
    width: 100%;
    height: 140px;
}

.box span{
    padding: 15px 10px;
}

.topbuttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}