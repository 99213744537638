.fieldset {
    border: 1px solid #29ABE2;
    border-radius: 10px;
    padding: 10px 20px 20px;
}

.fieldset legend {
    font-size: 16px;
    font-weight: bold;
    width: inherit;
    padding: 0 30px;
    color: #29ABE2;
    border: 1px solid #29ABE2;
    border-radius: 20px;
}