/* Table */
.actions {
    text-align: center;
    width: 15%;
}

.actionButton {
    margin-left: 2%;
    margin-right: 2%; 
}

/* Dynamic Inputs */
.tinyActions {
    position: relative;
}

.plusButton {
    position: absolute;
    width: 40%;
    bottom: 0;
    left: 10px;
}

.minusButton {
    position: absolute;
    width: 40%;
    bottom: 0;
    right: 10px;
}