.eachItem {
    padding: 20px 0 20px 0;
    /* margin: 0 -15px; */
}

.eachItem div {
    text-align: center;
    /* margin: 10px 0 10px 0; */
}

@media (max-width: 767px) {
    .eachItem {
        padding: 0 0 0 0;
    }

    .eachItem div {
        margin: 10px 0 10px 0;
    }
}