.table {
    border: 1px solid black;
    width: 100%;
}

.tipo {
   padding-left: 15px;
}

.titulo {
    text-align: center
}

.preparo {
    border: 1px solid black;
    text-align: justify;
    padding: 4px 0 4px 0;
    height: 50px;

    margin-left: 0px;
    margin-right: 0px;
    margin-top: 4px;
    margin-bottom: 25px;
}

.topbuttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}