.tiny {
    border: none;
    /* text-align: center; */
    padding: 4px 8px;
    border-radius: 20px;
    color: white;
    box-shadow: none !important;
    cursor: pointer;
}

.tiny:disabled {
    /* background-image: linear-gradient(to right, #75b9d6 , #99c2d3) !important; */
    opacity: 0.5;
}

.blueButton {
    background-image: linear-gradient(to right, #0071BC , #29ABE2) !important;
}

.blueButton:active {
    background-image: linear-gradient(to right, #0b4b75 , #028eca) !important;
}

.redButton {
    background-color: #C1272D;
}

.redButton:active {
    background-color: #a00207;
}

.greenButton {
    background-color: #39B54A;
}

.greenButton:active {
    background-color: #069219;
}