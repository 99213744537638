.borda {
    width: 80%;
    border: 1px solid black;
    text-align: center;
    font-size: 18px;
    padding: 4px 0 4px 0;
    height: 150px;
}

.corte {
    border: 1px dashed black;
    margin-top: 15%;
}

.lote {
    margin-top: 5%;
}