.actions {
    text-align: center;
    width: 15%;
}

.actionButton {
    margin-left: 2%;
    margin-right: 2%; 
}

fieldset {
    border: 1px solid black;
    padding: 10px;
}

fieldset legend {
    font-size: 20px;
    font-weight: bold;
    width: inherit;
    padding: 0 10px;
    border-bottom: none;
}

.atualizarButton {
    margin-top: 10px;
}