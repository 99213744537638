@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;1,400&display=swap');

.loginPage {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('./../../assets/images/login-background.jpg') no-repeat center;
    /* background-repeat: no-repeat !important; */
    background-color: #808080;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    /* overflow: auto; */
    overflow-x: hidden;
}

.label{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    margin-bottom: 4px;
    line-height: 20px;
    color: #101727;
}

@media screen {
    .loginPage {
        position: fixed;
        /* z-index: 1; */
        /* top: 0; */
        /* left: 0; */
    }
}