.card {
    margin-top: 20px;
}

.cardHeader {
    cursor: pointer;
    background-color: #bdbdbd;
}

.cardHeader button {
    float: right;
}

.actions {
    text-align: left;
    width: 15%;
}

.actionButton {
    /* margin-left: 2%; */
    margin-right: 2%; 
}

.hide {
    display: none;
}

.cancel {
    background-color: salmon !important;
}

.receive {
    background-color: seagreen !important;
}

.motivo {
    cursor: pointer;
    background-color: black;
    color: white;
    width: 1.2em;
    display: inline-block;
    font-weight: bold;
    text-align: center;
    border-radius: 100%;
    font-size: 12px;
    margin-left: 4px;
}