@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;1,400&display=swap);
/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
    font-family: 'Aller';
    font-style: normal;
    font-weight: normal;
    src: local('Aller'), url(/static/media/Aller_Rg.a644e18a.woff) format('woff');
}

@font-face {
    font-family: 'Aller-Light';
    font-style: normal;
    font-weight: normal;
    src: local('Aller-Light'), url(/static/media/Aller_Light.02d3f7e3.woff) format('woff');
}

@font-face {
    font-family: 'Aller Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Aller Italic'), url(/static/media/Aller_It.11365a16.woff) format('woff');
}


@font-face {
    font-family: 'Aller Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Aller Bold'), url(/static/media/Aller_Bd.35639100.woff) format('woff');
}


@font-face {
    font-family: 'Aller Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Aller Bold Italic'), url(/static/media/Aller_BdIt.2146c7a0.woff) format('woff');
}


@font-face {
font-family: 'Aller Display';
font-style: normal;
font-weight: normal;
src: local('Aller Display'), url(/static/media/AllerDisplay.342f525b.woff) format('woff');
}


@font-face {
font-family: 'Aller Light Italic';
font-style: normal;
font-weight: normal;
src: local('Aller Light Italic'), url(/static/media/Aller_LtIt.1c061752.woff) format('woff');
}
html {
  /* min-height: 100% !important; */
  /* position: relative; */
  /* background-image: url('./assets/images/image-background.png');
  background-repeat: no-repeat !important;
  background-size: 100% 100%; */
}

body {
  margin: 0;
  padding: 0;
  /* background-color: transparent; */
  background-color: #808080;
  /* min-height: 100% !important; */
  font-family: "Aller" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.my-navbar {
  background-color: #006837 !important;
  display: block;
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

@media print {
  body * {
    visibility: hidden;
  }

  .printable, .printable * {
    visibility: visible;
  }

  .hide * {
    visibility: hidden;
    display: none;
  }

  .printable {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
  }
}
.SelectMultiple_select__14Wpm{
  border-left: 2px ridge rgba(17, 153, 216, 0.5) !important;
}
.SelectMultiple_select__14Wpm:focus {
  border-radius: 0px 8px 8px 8px;
  border-left: 2px ridge rgba(17, 153, 216, 0.5) !important;
}
.ConfirmMessageTitle_alert__Azm1S {
    color: white !important;
    padding: 4px 40px 4px 40px;
    text-align: center;
    font-weight: bold;
    border: none;
    font-size: 20px;
    border-radius: 20px !important;
    background-color: #F7931E;
}

.ConfirmMessageTitle_alert__Azm1S i {
    margin-right: 10px;
}
header {
    box-shadow: 0px 4px 3px rgba(51,51,51,0.7);
    position: fixed;
    top: 0;
    width: 100% !important;
    z-index: 1000;
    background-image: linear-gradient(to right, #006837 , #009245) !important;
    /* font-family: 'Aller Bold'!important; */
    color:white!important;
    /* text-align: center; */
    padding: 5px 0 0 0;
    /* display: inline-grid; */
}

.Header_menuArea__3gSs8 {
    /* height: 100% !impor; */
}

.Header_menuButton__dWxhg {  
    margin: 9px 0 10px 10px;
    background-color: transparent;
    border: 0ch;
    cursor: pointer;
    color: white !important;
    /* text-align: left; */
}

.Header_iconVisibility__3KGnr {
    visibility: hidden;
}

@media (max-width: 767px) {
    .Header_iconVisibility__3KGnr {
        visibility: visible;
    }
}

.Header_headerTitle__3IvWC {
    margin: 5px 0 6px 0;
    text-align: center;
}
.fa-2x {
    font-size: 2em;
}

.fa {
    position: relative;
    display: table-cell;
    width: 60px;
    height: 36px;
    text-align: center;
    vertical-align: middle;
    font-size: 20px;
}

.main-menu:hover,
nav.main-menu.expanded {
    width: 250px;
    overflow: visible;
    overflow-y: auto;
}

.main-menu {
    padding-top: 70px;
    background: #212121;
    /* border-right: 1px solid #e5e5e5; */
    position: fixed;
    /* top: 25px; */
    bottom: 0;
    height: 100%;
    left: 0;
    width: 60px;
    overflow: hidden;
    transition: width .05s linear;
    -webkit-transform: translateZ(0) scale(1, 1);
    z-index: 999;
    float: left;
}

.main-menu>ul {
    margin: 7px 0;
}

.main-menu li, .main-menu div {
    position: relative;
    display: block;
    width: 250px;
}

.subMenuSpace {
    padding-left: 30px;
}

.subSubMenuSpace {
    padding-left: 60px;
}

.main-menu li>a, .main-menu div>a {
    position: relative;
    display: table;
    border-collapse: collapse;
    border-spacing: 0;
    color: #B3B3B3;
    font-family: arial;
    font-size: 14px;
    text-decoration: none;
    -webkit-transform: translateZ(0) scale(1, 1);
    transition: all .1s linear;
    margin-bottom: 20px;
}

/* Adaptações para responsividade */
@media (max-width: 767px) {
    .isVisible {
        visibility: visible;
    }

    .isNotVisible {
        visibility: hidden;
    }

    .main-menu {
        padding-top: 20px !important;
        background: #212121;
        position: fixed;
        bottom: 0;
        height: 100%;
        left: 0;
        width: 250px;
        overflow: visible;
        overflow-y: auto;
        z-index: 1001;
        float: left; 
    }

    .main-menu li>a, .main-menu div>a {
        position: relative;
        display: table;
        /* border-collapse: collapse; */
        border-spacing: 0;
        color: #B3B3B3;
        font-family: arial;
        font-size: 14px;
        text-decoration: none;
        -webkit-transform: translateZ(0) scale(1, 1);
        transition: all .1s linear;
        margin-bottom: 20px;
    }

    .main-menu:hover,
    nav.main-menu.expanded {
        
    }

    .sidebar-content {
        margin-top: 90px !important;
        margin-left: 0px !important;
    }
}

/* 
.main-menu .nav-icon {
    position: relative;
    display: table-cell;
    width: 60px;
    height: 36px;
    text-align: center;
    vertical-align: middle;
    font-size: 18px;
} */

.main-menu .nav-text {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    width: 190px;
    font-family: 'Titillium Web', sans-serif;
}

.main-menu>ul.logout {
    position: absolute;
    left: 0;
    bottom: 0;
}

.no-touch .scrollable.hover {
    overflow-y: hidden;
}

.no-touch .scrollable.hover:hover {
    overflow-y: auto;
    overflow: visible;
}

/* a:hover,
a:focus {
    text-decoration: none;
} */

nav {
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
}

nav ul,
nav li {
    outline: 0;
    margin: 0;
    padding: 0;
}

.main-menu li:hover>a,
.main-menu div:hover>a,
.no-touch .dashboard-page nav.dashboard-menu ul li:hover a,
.dashboard-page nav.dashboard-menu ul li.active a {
    color: #fff;
    text-decoration: underline;
    /* text-decoration-color: #0071BC; */
}

.sidebar-content {
    margin-top: 55px;
    margin-left: 60px;
    padding: 0 30px 0 30px;
    /* background-color: #808080; */
    /* height: 100% !important; */
    
    
    /* position: absolute;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    overflow-y: scroll;
    z-index: -1; */
}

@media (max-width: 426px) {
    .sidebar-content {
        margin-top: 100px !important;
    }
}
.Subheader_subheader__291rx {
    padding: 0 32px 0 35px;
}

@media (max-width: 478px) {
    .Subheader_subheader__291rx {
        padding: 0 0 0 15px;
    }   
}

@media (max-width: 426px) {
    .Subheader_subheader__291rx {
        padding: 0 15px 0 15px;
    }   
}

.Subheader_leftItens__1aXoy {
    text-align: left;
}

.Subheader_rightItens__ymP8L {
    text-align: right;
}
.SubheaderLogo_flexContainer__Fw7aJ {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.SubheaderLogo_item__3nhwh {
    color: transparent;
}

.SubheaderLogo_logo__jaJA6 {
    width: 100px;
    height: auto;
}

@media (max-width: 426px) {
    .SubheaderLogo_logo__jaJA6 {
        width: 80px;
        height: auto;
    }    
}

/* @media (max-width: 384px) {
    .logo {
        width: 75px;
        height: auto;
    } 
}

@media (max-width: 375px) {
    .logo {
        width: 70px;
        height: auto;
    }   
}

@media (max-width: 339px) {
    .logo {
        width: 60px;
        height: auto;
    }
} */
.SubheaderUser_flexContainer__1PA_u {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.SubheaderUser_item__Ai6l5 {
    color: transparent;
}

.SubheaderUser_flexContainerUserdatas__5Qjhu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-right: -26px !important;
}

.SubheaderUser_welcomeMessage__33hfe {
    color: white;
    opacity: inherit;
    font-size: 12px;
    white-space: nowrap; 
}

.SubheaderUser_username__1MrSK {
    font-weight: bold;
    color: white;
    white-space: nowrap;
}

.SubheaderUser_userimage__2yglN {
    width: 55px;
    height: auto;
}

.SubheaderUser_userImageContainer__3Wu8K {
    width: 60px; 
    height: 60px;
    position: relative;
}

.SubheaderUser_badge__39KbY {
    position: absolute;
    top: 0;
    right: 0;
}

.SubheaderUser_userImageClick__3UHvg {
    z-index: 10;
}

.SubheaderUser_userPopover__22sSf {
    margin-top: 0px !important;
    width: 400px;
    background-color: white;
    border: 1px solid #A3A3A3;
    border-radius: 10px;
    padding: 4px 10px;
    left: -133px !important;
    /* top: 60px !important; */
    /* height: 200px; */
    /* margin-right: 20px !important; */
}

.SubheaderUser_closeButton__2ZmQE{
    background: red;
    width: 24px;
    height: 26px;
    text-align: center;
    color: white;
    border-radius: 21px;
    position: absolute;
    right: -13px;
    cursor: pointer;
    top: -14px;
}

@media (max-width: 700px) {
    .SubheaderUser_flexContainerUserdatas__5Qjhu {
        display: none;
        margin-right: -26px !important;
    }

    /* .welcomeMessage {
        font-size: 10px;
    } */
    
    .SubheaderUser_userimage__2yglN {
        width: 40px;
        height: auto;
    }   
}

@media (max-width: 426px) {
    .SubheaderUser_flexContainerUserdatas__5Qjhu {
        display: none;
        margin-right: -26px !important;
    }

    /* .welcomeMessage {
        font-size: 10px;
    } */
    
    .SubheaderUser_userimage__2yglN {
        width: 40px;
        height: auto;
    }   
}

/* @media (max-width: 384px) {
    .welcomeMessage {
        font-size: 8px;
    }
    
    .userimage {
        width: 35px;
        height: auto;
    }   
}

@media (max-width: 375px) {
    .welcomeMessage {
        font-size: 7px;
    }
    
    .userimage {
        width: 30px;
        height: auto;
    }   
}

@media (max-width: 339px) {
    .welcomeMessage {
        font-size: 8px;
    }
    
    .userimage {
        width: 30px;
        height: auto;
    }  
} */
.Notifications_notifications__1MWmT {
    /* padding-right: 0 !important; */
    /* overflow-y: scroll; */
}

.Notifications_notifications__1MWmT ul {
    list-style-type: none;
    padding: 0;
    margin-top: 6px;
    overflow-y: scroll;
    height: 300px;
    padding-right: 8px;
    margin-right: -48px;
}

.Notifications_notifications__1MWmT li {
    /* border: 1px solid rgba(0, 0, 0, 0.1); */
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.1);
    background-color: #f0efef;
    font-size: 14px;
    width: 100%;
    padding: 6px 10px 6px 10px;
    margin: 8px 0;
    text-align: justify;
}
.MainContent_mainContent__1zpxm {
    background-color: #E6E6E6;
    border-radius: 10px;
    /* border-top-right-radius: 10px; */
    padding: 20px 50px 20px 50px !important;
    /* height: 100%; */
    /* overflow-y: scroll; */
}

@media (max-width: 530px) {
    .MainContent_mainContent__1zpxm {
        padding: 20px 10px 20px 10px !important;
    }   
}
.LoginForm_loginForm__3R5hY {
    width: 370px;
    display: flex;
    flex-direction: column;
    padding: 20px 27px;
    background: rgba(255, 255, 255, 0.2);
    background-blend-mode: overlay;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    -webkit-backdrop-filter: blur(50px);
            backdrop-filter: blur(50px);
    border-radius: 10px;
}

.LoginForm_blocoLinks__2l5IQ{
    width: -webkit-max-content;
    width: max-content;
    display: flex;
    grid-gap:6px;
    gap:6px;
    margin: 10px auto;
}
.LoginForm_nomeEscola__1gH1G{
    line-height: 17px;
    text-align: center;
    margin-bottom: -13px;
}
.LoginForm_nomeEscola__1gH1G>img{
    margin-bottom: 14px;
}
.LoginForm_nomeEscola__1gH1G>p{
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
}

@media (max-width: 940px) {
    .LoginForm_loginForm__3R5hY {
        width: 30%;
    }
}

@media (max-width: 810px) {
    .LoginForm_loginForm__3R5hY {
        width: 36%;
    }
}

@media (max-width: 700px) {
    .LoginForm_loginForm__3R5hY {
        width: 45%;
    }
}

@media (max-width: 600px) {
    .LoginForm_loginForm__3R5hY {
        width: 55%;
    }
}

@media (max-width: 476px) {
    .LoginForm_loginForm__3R5hY {
        width: 65%;
        padding: 15px 30px 30px 30px;
    }
}

.LoginForm_loginForm__3R5hY form {
    margin-top: 20px;
}

.LoginForm_loginTitle__2ymtq {
    font-weight: bold;
    font-size: 24px;
}

p{
    font-size: 1.2rem;
}

p + p {
    margin-top: -10px;
}
.LoginInputForm_input__2SXPQ {
    height: 44px;
    background: #FFFFFF;
    border: 1px solid #C7C8CC;
    box-sizing: border-box;
    border-radius: 10px;
    margin-bottom: 17px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    color: #4C4C4E;
}

.LoginInputForm_input__2SXPQ::placeholder {
    color: grey;
}
.SimpleButton_button__BnoD5 {
    color: white !important;
    padding: 0 40px 0 40px;
    text-align: center;
    font-weight: bold;
    border: none;
    font-size: 18px;
    border-radius: 20px !important;
    cursor: pointer;
}

.SimpleButton_buttonLogin__cBVT_{
    width: 100%;
    height: 49px;
    background: #101727;
    border: 1px solid #101727;
    box-sizing: border-box;
    border-radius: 10px;
    text-decoration: none;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    display: grid;
    place-items: center;
    font-size: 18px;
    color: #FFFFFF;
    margin-top: 12px;
    cursor: pointer;
}

.SimpleButton_button__BnoD5 i {
    margin-right: 10px;
}

.SimpleButton_button__BnoD5:focus, .SimpleButton_button__BnoD5:active {
    outline: none !important;
    box-shadow: none;
    border-color: #29ABE2;
}

.SimpleButton_button__BnoD5:disabled {
    /* background-image: linear-gradient(to right, #75b9d6 , #99c2d3) !important; */
    opacity: 0.5;
}

.SimpleButton_blueButton__qshva {
    background-image: linear-gradient(to right, #0071BC , #29ABE2) !important;
}

.SimpleButton_blueButton__qshva:active {
    background-image: linear-gradient(to right, #0b4b75 , #028eca) !important;
}

.SimpleButton_redButton__Sam1R {
    background-color: #C1272D;
}
.SimpleButton_redButton__Sam1R:active {
    background-color: #a00207;
}

.SimpleButton_yellowButton__3TXcp {
    background-color: #c4bc25;
}

.SimpleButton_yellowButton__3TXcp:active {
    background-color: #c17c27;
}

.SimpleButton_greenButton__4Djie {
    background-color: #39B54A;
}

.SimpleButton_greenButton__4Djie:active {
    background-color: #069219;
}
.Login_loginPage__dVapF {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(/static/media/login-background.24d4eae0.jpg) no-repeat center;
    /* background-repeat: no-repeat !important; */
    background-color: #808080;
    background-size: cover;
    /* overflow: auto; */
    overflow-x: hidden;
}

.Login_label__3ojJA{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    margin-bottom: 4px;
    line-height: 20px;
    color: #101727;
}

@media screen {
    .Login_loginPage__dVapF {
        position: fixed;
        /* z-index: 1; */
        /* top: 0; */
        /* left: 0; */
    }
}
.LinkButton_blocoLink__10_Hc{
    text-align: center;
    margin-bottom: 10px;
}
.LinkButton_blocoLink__10_Hc>a{
    color: #FFF !important;
    cursor: pointer;
    text-decoration: underline !important;
}
.LinkButton_blocoLink__10_Hc>a:hover{
    color: #180000 !important;
    
}
.ModalTitle_modalTitle__3J2a3 {
    color: white !important;
    padding: 4px 40px 4px 40px;
    text-align: center;
    font-weight: bold;
    border: none;
    font-size: 20px;
    border-radius: 20px !important;
    background-image: linear-gradient(to right, #0071BC , #29ABE2) !important;    
}

.ModalTitle_modalTitle__3J2a3 i {
    margin-right: 10px;
}
.SimpleModal_simpleModal__2W6aM > div {
    max-width: 1200px;
}

/* Modal */
.SimpleModal_simpleModal__2W6aM > div > div {
    border-radius: 20px !important;
}

/* Cabeçalho da modal */
.SimpleModal_simpleModal__2W6aM > div > div > div:first-child {
    text-align: center !important;
    border: none;
    display: inline-block;
    position: relative;
    height: 0 !important;
    margin-bottom: 28px;
}

/* Titulo da modal */
.SimpleModal_simpleModal__2W6aM > div > div > div > div {
    /* border: 2px solid red !important; */
}

/* Botão de fechar modal */
.SimpleModal_simpleModal__2W6aM > div > div > div > button {
    position: relative;
    top: -40px;
    /* background-color: #C1272D; */
    /* color: white; */
    /* opacity: 1; */
    /* border-radius: 20px; */
    /* padding: 0; */
    /* border-top: none; */
    /* border: 2px solid red !important; */
}

.SimpleModal_simpleModal__2W6aM > div > div > div > button:focus {
    outline: none !important;
}
.Input_label__fZjJm {
    border: 2px solid #0071BC;
    color:white !important;
    background-color: #0071BC;
    border-radius: 8px;
    height: 42px;
}

.Input_input__2Eamj {
    border: 2px solid #29ABE2;
    border-left: none;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    height: 42px;
    /* text-transform: uppercase; */
}

.Input_aside__t5Vzu {
    height: 34px;
}

.Input_aside__t5Vzu > span {
    background-color: transparent !important;
    border: none;
    cursor: pointer;
}

.Input_input__2Eamj:focus, .Input_input__2Eamj:active {
    outline: none !important;
    box-shadow: none;
    border-color: #29ABE2;
}
.SimpleButtonLogin_button__Fc3JG {
    color: white !important;
    padding: 0 40px 0 40px;
    text-align: center;
    font-weight: bold;
    border: none;
    font-size: 18px;
    border-radius: 20px !important;
    cursor: pointer;
}

.SimpleButtonLogin_buttonLogin__5esVx{
    width: 100%;
    height: 49px;
    background: #101727;
    border: 1px solid #101727;
    box-sizing: border-box;
    border-radius: 10px;
    text-decoration: none;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    display: grid;
    place-items: center;
    font-size: 18px;
    color: #FFFFFF;
    margin-top: 12px;
    cursor: pointer;
}

.SimpleButtonLogin_button__Fc3JG i {
    margin-right: 10px;
}

.SimpleButtonLogin_button__Fc3JG:focus, .SimpleButtonLogin_button__Fc3JG:active {
    outline: none !important;
    box-shadow: none;
    border-color: #29ABE2;
}

.SimpleButtonLogin_button__Fc3JG:disabled {
    /* background-image: linear-gradient(to right, #75b9d6 , #99c2d3) !important; */
    opacity: 0.5;
}

.SimpleButtonLogin_blueButton__1F0w9 {
    background-image: linear-gradient(to right, #0071BC , #29ABE2) !important;
}

.SimpleButtonLogin_blueButton__1F0w9:active {
    background-image: linear-gradient(to right, #0b4b75 , #028eca) !important;
}

.SimpleButtonLogin_redButton__257a5 {
    background-color: #C1272D;
}
.SimpleButtonLogin_redButton__257a5:active {
    background-color: #a00207;
}

.SimpleButtonLogin_yellowButton__31fXt {
    background-color: #c4bc25;
}

.SimpleButtonLogin_yellowButton__31fXt:active {
    background-color: #c17c27;
}

.SimpleButtonLogin_greenButton__1dSRH {
    background-color: #39B54A;
}

.SimpleButtonLogin_greenButton__1dSRH:active {
    background-color: #069219;
}
.PageTitle_pageTitle__1_iKq {
    color: #006837;
    font-weight: bold;
    font-size: 26px;
    width: 100%;
    margin-bottom: 20px;
}

.PageTitle_customLine__rb6Lb {
    border-bottom: 1px solid #29ABE2;
    color: red;
    text-align: right;
    font-size: 11px;
    font-weight: normal;
}
.HomeItem_homeItem__1ZwLK {
    cursor: pointer;
    margin-bottom: 80px;
}

.HomeItem_homeItem__1ZwLK div {
    border: 1px solid #0071BC;
    font-size: 18px;
    font-weight: normal;
    border-radius: 30px;
    padding: 4px 40px 4px 40px;
    margin-top: 20px;
    min-width: auto !important;
    display: inline-block;
    color: #0071BC;
}
.SubItem_subItem__MhdA2 {
    margin: 5px 0 5px 0;
    /* opacity: 1 !important; */
}

.SubItem_subItem__MhdA2 * {
    display: inline-block;
}

.SubItem_subItem__MhdA2 a {
    text-decoration: none;
    border-radius: 10px;
    border: 1px solid white;
    padding: 2px 20px 0 20px;
    background-color: white;
    box-shadow: 3px 3px rgba(128, 128, 128, 0.2);
}

/* .subItem a:hover {
    border-color: 
} */

.SubItem_iconSubmenu__3syF4 {
    margin-right: 5px;
}

.SubItem_nameSubmenu__3bkJ6 {
    margin-left: 5px;
    color: #666666;
}
.HomeItemOverlay_homeItemOverlayPopoverOver__3BEfw {
    margin-top: 0px !important;
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    z-index: 10000;
}

.HomeItemOverlay_homeItemOverlayPopover__34mBy {
    margin-top: 0px !important;
    background-color: rgba(0, 0, 0, 0);
    border: 0;
}
.HomeItemModal_eachModalItem__1qd_z {
    text-align: center;
}

.HomeItemModal_modal__1J3lY {
    padding: 50px 4px 10px 4px !important;
}
.Home_eachItem__3ULkN {
    padding: 20px 0 20px 0;
    /* margin: 0 -15px; */
}

.Home_eachItem__3ULkN div {
    text-align: center;
    /* margin: 10px 0 10px 0; */
}

@media (max-width: 767px) {
    .Home_eachItem__3ULkN {
        padding: 0 0 0 0;
    }

    .Home_eachItem__3ULkN div {
        margin: 10px 0 10px 0;
    }
}
.TextInputMask_label__1PRDn {
    border: 2px solid #0071BC;
    color:white !important;
    background-color: #0071BC;
    border-radius: 8px;
    height: 42px;
}

.TextInputMask_inputMask__UAwLE {
    border: 2px solid #29ABE2;
    border-left: none;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    height: 42px;
}

.TextInputMask_loading__CCGdo {
    height: 42px;
}

.TextInputMask_loading__CCGdo > span {
    background-color: transparent !important;
    border: none;
}

.TextInputMask_inputMask__UAwLE:focus, .TextInputMask_inputMask__UAwLE:active {
    outline: none !important;
    box-shadow: none;
    border-color: #29ABE2;
}
.Fieldset_fieldset__25rN5 {
    border: 1px solid #29ABE2;
    border-radius: 10px;
    padding: 10px 20px 20px;
}

.Fieldset_fieldset__25rN5 legend {
    font-size: 16px;
    font-weight: bold;
    width: inherit;
    padding: 0 30px;
    color: #29ABE2;
    border: 1px solid #29ABE2;
    border-radius: 20px;
}
.PhotoUpload_photoUpload__3wu1z {
    text-align: center;
}

.PhotoUpload_userImage__2KEiF {
    width: 100px; 
    height: auto;
    margin-bottom: 6%;
}
/* .label {
    border: 2px solid #0071BC;
    color:white !important;
    background-color: #0071BC;
    border-radius: 8px;
    height: 34px;
} */

.FileInput_file__1IQ4L {
    width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.FileInput_file__1IQ4L + label {
    font-size: 1.25em;
    font-weight: 700;
    color: white;
    background-image: linear-gradient(to right, #0071BC , #29ABE2) !important;
    display: inline-block;
    font-size: 18px;
    border-radius: 20px !important;
    padding: 0 40px 0 40px;
}

.FileInput_file__1IQ4L:focus + label,
.FileInput_file__1IQ4L + label:hover {
    background-color: red;
}

.FileInput_file__1IQ4L + label {
	cursor: pointer; /* "hand" cursor */
}

.FileInput_file__1IQ4L + label * {
	pointer-events: none;
}

/* .file {
    border: 2px solid #29ABE2;
    border-left: none;
    border-radius: 8px;
    height: 34px;
}

.file:focus, .file:active {
    outline: none !important;
    box-shadow: none;
    border-color: #29ABE2;
} */
.BackButton_backbutton__13VaF {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.SimpleSelect_label__sCXjS {
    border: 2px solid #0071BC;
    color:white !important;
    background-color: #0071BC;
    border-radius: 8px;
    height: 42px;
}

.SimpleSelect_select__Zrhzj {
    border: 2px solid #29ABE2;
    border-left: none;
    border-radius: 8px;
    height: 42px;
}

.SimpleSelect_select__Zrhzj:focus, .SimpleSelect_select__Zrhzj:active {
    outline: none !important;
    box-shadow: none;
    border-color: #29ABE2;
}
.SimpleTable_table__3Hifg {
    /* border-top-left-radius: 10px !important; */
    /* border-top-right-radius: 10px !important; */
}

.SimpleTable_table__3Hifg thead {
    text-align: center;
    background-image: linear-gradient(to right, #0071BC , #29ABE2) !important;    
    color: white;
}

.SimpleTable_table__3Hifg thead th {
    vertical-align: middle;
    padding-top: 0;
    padding-bottom: 0;
}

.SimpleTable_table__3Hifg thead th:first-child {
    border-top-left-radius: 10px;
}  

.SimpleTable_table__3Hifg thead th:last-child {
    border-top-right-radius: 10px;
}

.SimpleTable_table__3Hifg tbody {
    text-align: center;
    border: none !important;
}

.SimpleTable_table__3Hifg tbody tr:nth-child(even) {
    background-color: #b8cdc3;
}

.SimpleTable_table__3Hifg tbody td {
    vertical-align: middle !important;
    padding-top: 0;
    padding-bottom: 0;
    border-left: 1px solid #29ABE2;
    border-right: 1px solid #29ABE2;
}

.SimpleTable_table__3Hifg tbody td:first-child {
    border-left: none;
}

.SimpleTable_table__3Hifg tbody td:last-child {
    border-right: none;
}


.CadastrarUsuario_cardHeader__Rcvok {
    cursor: pointer;
}

.CadastrarUsuario_cardHeader__Rcvok button {
    float: right;
}

.CadastrarUsuario_actions__1U_gW {
    text-align: left;
    width: 15%;
}

.CadastrarUsuario_actionButton__2RINL {
    /* margin-left: 2%; */
    margin-right: 2%; 
}

.CadastrarUsuario_hide__iZXPC {
    display: none;
}

.CadastrarUsuario_listItem__2B-LL button {
    float: right;
}
.ActionButton_actionButton__2g6TB {
    text-align: center;
    padding: 4px 8px;
    border-radius: 10px;
    background-color: #E6E6E6;
    box-shadow: none !important;
    border: 1px solid #29ABE2;
    margin: 2% 2%;
    cursor: pointer;
}

.ActionButton_actionButton__2g6TB:active {
    background-color: #cecdcd;
}

.ActionButton_actionButton__2g6TB:disabled {
    cursor: not-allowed;
}

.ActionButton_actionButton__2g6TB:focus, .ActionButton_actionButton__2g6TB:active {
    outline: none !important;
    box-shadow: none;
    border-color: #29ABE2;
}
.GerenciarUsuarios_cardHeader__kA1Pv {
    cursor: pointer;
}

.GerenciarUsuarios_cardHeader__kA1Pv button {
    float: right;
}

.GerenciarUsuarios_actions__VviTt {
    text-align: center;
    width: 15%;
}

.GerenciarUsuarios_actionButton__2y91U {
    margin-left: 2%;
    margin-right: 2%; 
}

.GerenciarUsuarios_listItem__wJCKt button {
    float: right;
}
.GerenciarItens_actions__TkDA6 {
    text-align: center;
    width: 15%;
}

.GerenciarItens_actionButton__1WWHM {
    margin-left: 2%;
    margin-right: 2%; 
}
/* .myDiv:first-child {
    border: 2px solid red !important;
    min-width: 100%;
} */

/* .manucu {
    position: relative;
    min-width: 100%;
} */

.SearchSelect_label__2fAVD {
    border: 2px solid #0071BC;
    color:white !important;
    background-color: #0071BC;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-left: none;
    height: 42px;
    padding: 4px 14px;
    float: left;
    /* display: inline-block; */
}

.SearchSelect_select__376MR {
    /* border: 2px solid #29ABE2 !important; */
    
    /* background-color: white; */
    color: #495057;
    /* padding-left: 10px !important; */
    /* display: inline-block; */
    /* width: auto !important; */
}

.SearchSelect_select__376MR > div {
    /* background-color: white; */
    border: 2px solid #29ABE2;
    /* height: 20px !important; */
    border-left: none !important;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
}

.SearchSelect_select__376MR > div:hover {
    border: 2px solid #29ABE2;
}

.SearchSelect_select__376MR > div:nth-child(3) {
    border: none;
}

/* .select {
    border: 2px solid #29ABE2 !important;
    border-left: none !important;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    background-color: white;
    color: #495057;
    padding-left: 10px !important;
    min-height: 34px !important;
    display: inline-block;
    width: auto !important;
} */

/* .select div:nth-child(4) {
    width: 100%;
} */

.SearchSelect_select__376MR:focus, .SearchSelect_select__376MR:active {
    outline: none !important;
    box-shadow: none !important;
    border-color: #29ABE2 !important;
}
.GerenciarProdutos_actions__2hPxh {
    text-align: center;
    width: 15%;
}

.GerenciarProdutos_actionButton__13yF9 {
    margin-left: 2%;
    margin-right: 2%; 
}
.GerenciarFornecedores_actions__35nR9 {
    text-align: center;
    width: 15%;
}

.GerenciarFornecedores_actionButton__2wjCX {
    margin-left: 2%;
    margin-right: 2%; 
}

fieldset {
    border: 1px solid black;
    padding: 10px;
}

fieldset legend {
    font-size: 20px;
    font-weight: bold;
    width: inherit;
    padding: 0 10px;
    border-bottom: none;
}

.GerenciarFornecedores_atualizarButton__2rKHs {
    margin-top: 10px;
}
.SimpleModalSmall_simpleModal__sREUu > div {
    max-width: 400px;
}

/* Modal */
.SimpleModalSmall_simpleModal__sREUu > div > div {
    border-radius: 20px !important;
}

/* Cabeçalho da modal */
.SimpleModalSmall_simpleModal__sREUu > div > div > div:first-child {
    text-align: center !important;
    border: none;
    display: inline-block;
    position: relative;
    height: 0 !important;
    margin-bottom: 28px;
}

/* Titulo da modal */
.SimpleModalSmall_simpleModal__sREUu > div > div > div > div {
    /* border: 2px solid red !important; */
}

/* Botão de fechar modal */
.SimpleModalSmall_simpleModal__sREUu > div > div > div > button {
    position: relative;
    top: -40px;
    /* background-color: #C1272D; */
    /* color: white; */
    /* opacity: 1; */
    /* border-radius: 20px; */
    /* padding: 0; */
    /* border-top: none; */
    /* border: 2px solid red !important; */
}

.SimpleModalSmall_simpleModal__sREUu > div > div > div > button:focus {
    outline: none !important;
}
.Textarea_label__1Ho5b {
    border: 2px solid #0071BC;
    color:white !important;
    background-color: #0071BC;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: none;
    height: 42px;
    padding: 4px 14px;
    /* display: inline-block; */
}

.Textarea_textarea__1_Cdb {
    border: 2px solid #29ABE2 !important;
    border-top: none !important;
    border-bottom-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    background-color: white;
    color: #495057;
    padding-left: 14px !important;
}

.Textarea_textarea__1_Cdb:required {
    box-shadow: none !important;
}

.Textarea_textarea__1_Cdb:focus, .Textarea_textarea__1_Cdb:active {
    outline: none !important;
    box-shadow: none !important;
    border-color: #29ABE2 !important;
}
.TinyAction_tiny__2TeXa {
    border: none;
    /* text-align: center; */
    padding: 4px 8px;
    border-radius: 20px;
    color: white;
    box-shadow: none !important;
    cursor: pointer;
}

.TinyAction_tiny__2TeXa:disabled {
    /* background-image: linear-gradient(to right, #75b9d6 , #99c2d3) !important; */
    opacity: 0.5;
}

.TinyAction_blueButton__1PGc4 {
    background-image: linear-gradient(to right, #0071BC , #29ABE2) !important;
}

.TinyAction_blueButton__1PGc4:active {
    background-image: linear-gradient(to right, #0b4b75 , #028eca) !important;
}

.TinyAction_redButton__2Ltd_ {
    background-color: #C1272D;
}

.TinyAction_redButton__2Ltd_:active {
    background-color: #a00207;
}

.TinyAction_greenButton__2xzw7 {
    background-color: #39B54A;
}

.TinyAction_greenButton__2xzw7:active {
    background-color: #069219;
}
.MoneyInputMask_label__C4scF {
    border: 2px solid #0071BC;
    color:white !important;
    background-color: #0071BC;
    border-radius: 8px;
    height: 34px;
}

.MoneyInputMask_input__2s0A_ {
    border: 2px solid #29ABE2;
    border-left: none;
    border-radius: 8px;
    height: 34px;
}

.MoneyInputMask_input__2s0A_:focus, .MoneyInputMask_input__2s0A_:active {
    outline: none !important;
    box-shadow: none;
    border-color: #29ABE2;
}
.CadastrarAquisicao_propostas__2tR6j {
    /* border: 1px solid black; */
    border-radius: 10px;
    padding: 8px 12px;
    margin-bottom: 6px;
}

.CadastrarAquisicao_propostas__2tR6j:nth-child(even) {
    background-color: #b8cdc3 !important;
}

hr {
    background-color: #707070 !important;
}

.CadastrarAquisicao_itens__1D6ug {
    margin-top: 16px;
}

.CadastrarAquisicao_valorTotalItem__1CFs7 {
    font-size: 14px;
    margin-top: -10px !important;
}

.CadastrarAquisicao_tinyActions__3Kpj_ {
    position: relative;
}

.CadastrarAquisicao_plusButton__2WBG1 {
    position: absolute;
    width: 40%;
    bottom: 0;
    left: 10px;
}

.CadastrarAquisicao_minusButton__1JWso {
    position: absolute;
    width: 40%;
    bottom: 0;
    right: 10px;
}

.CadastrarAquisicao_cardHeader__dgpil {
    cursor: pointer;
}

.CadastrarAquisicao_cardHeader__dgpil button {
    float: right;
}

.CadastrarAquisicao_actions__2ONQ0 {
    text-align: left;
    width: 15%;
}

.CadastrarAquisicao_actionButton__3k5WA {
    /* margin-left: 2%; */
    margin-right: 2%; 
}

.CadastrarAquisicao_hide__ZULeG {
    display: none;
}
.GerenciarAquisicoes_actions__2UrDE {
    text-align: center;
    width: 15%;
}

.GerenciarAquisicoes_actionButton__2wKH5 {
    margin-left: 2%;
    margin-right: 2%; 
}

.GerenciarAquisicoes_propostas__1y-eg {
    border-radius: 10px;
    padding: 8px 12px;
    margin-bottom: 6px;
}

.GerenciarAquisicoes_propostas__1y-eg:nth-child(even) {
    background-color: #b8cdc3 !important;
}

hr {
    background-color: #707070 !important;
}

.GerenciarAquisicoes_itens__vAAaT {
    margin-top: 16px;
}

.GerenciarAquisicoes_valorTotalItem__gz_aZ {
    font-size: 14px;
    margin-top: -10px !important;
}

.GerenciarAquisicoes_cancel__3eoYX {
    background-color: salmon !important;
}

.GerenciarAquisicoes_motivo__3JrUQ {
    cursor: pointer;
    background-color: black;
    color: white;
    width: 1.2em;
    display: inline-block;
    font-weight: bold;
    text-align: center;
    border-radius: 100%;
    font-size: 12px;
    margin-left: 4px;
}

.GerenciarAquisicoes_vigente__aFcVJ {
    color: green;
    font-weight: bolder;
}

.GerenciarAquisicoes_cancelada__2Wli6 {
    color: red;
    font-weight: bolder;
}
.Aditivos_actions__2qGu2 {
    text-align: center;
    width: 15%;
}

.SelectMultiple_bloco__1P9RF{
   display: flex;
   width: 100%;
   margin-bottom: 12px;
}
.SelectMultiple_label__2PYOh {
    border: 2px solid #0071BC;
    color:white !important;
    background-color: #0071BC;
    border-radius: 8px 0px 0px 8px;
    height: 42px;
    
}

.SelectMultiple_select__V8kT3 {
    border: 2px solid #29ABE2;
    border-left: none;
    border-radius: 0px 8px 8px 0px;
    width: 460px;
    height: 42px;
    display: inline-table;
    overflow: visible;
    margin-top: 0px;
    
}

.SelectMultiple_select__V8kT3:focus, .SelectMultiple_select__V8kT3:active {
    outline: none !important;
    box-shadow: none;
    border-color: #29ABE2;
}
.SimpleInput_input__2V6i_ {
    border: 2px solid #29ABE2;
    border-radius: 8px;
    height: 42px;
    margin: 4px 0;
    text-align: center;
}

.SimpleInput_input__2V6i_:focus, .SimpleInput_input__2V6i_:active {
    outline: none !important;
    box-shadow: none;
    border-color: #29ABE2;
}
.GerenciarSolicitacoes_actions__1Xjzy {
    text-align: center;
    width: 15%;
}

.GerenciarSolicitacoes_actionButton__gtFvR {
    margin-left: 2%;
    margin-right: 2%; 
}

.GerenciarSolicitacoes_cancel__1Yl4W {
    background-color: salmon !important;
}

.GerenciarSolicitacoes_receive__2hTMd {
    background-color: seagreen !important;
}

.GerenciarSolicitacoes_tinyActions__32D-K {
    position: relative;
}

.GerenciarSolicitacoes_hide__3OQTc {
    visibility: hidden;
}

.GerenciarSolicitacoes_plusButton__1tOgp {
    position: absolute;
    width: 40%;
    bottom: 0;
    left: 10px;
}

.GerenciarSolicitacoes_minusButton__1CISy {
    position: absolute;
    width: 40%;
    bottom: 0;
    right: 10px;
}

.GerenciarSolicitacoes_motivo__-vzQB {
    cursor: pointer;
    background-color: black;
    color: white;
    width: 1.2em;
    display: inline-block;
    font-weight: bold;
    text-align: center;
    border-radius: 100%;
    font-size: 12px;
    margin-left: 4px;
}

.GerenciarSolicitacoes_colunaTabelaRecebimento__3sS1T {
    width: 100px;
}

.GerenciarSolicitacoes_colunaRecebimentoQuantidade__3ItLU {
    width: 200px;
}

.GerenciarSolicitacoes_invalidField__1Xejj {
    border: 1px solid red;
}
.Estoque_cardHeader__rNz2s {
    cursor: pointer;
}

.Estoque_cardHeader__rNz2s button {
    float: right;
}

.Estoque_actions__19WOT {
    text-align: center;
    width: 15%;
}

.Estoque_actionButton__2voBJ {
    margin-right: 2%;
}

.Estoque_hide__1tsHk {
    display: none;
}
.GerenciarMovimentacoes_card__wbpsu {
    margin-top: 20px;
}

.GerenciarMovimentacoes_cardHeader__1oOEk {
    cursor: pointer;
    background-color: #bdbdbd;
}

.GerenciarMovimentacoes_cardHeader__1oOEk button {
    float: right;
}

.GerenciarMovimentacoes_actions__LLkBz {
    text-align: left;
    width: 15%;
}

.GerenciarMovimentacoes_actionButton__Ppb4W {
    /* margin-left: 2%; */
    margin-right: 2%; 
}

.GerenciarMovimentacoes_hide__3QAxR {
    display: none;
}

.GerenciarMovimentacoes_cancel__ExRYB {
    background-color: salmon !important;
}

.GerenciarMovimentacoes_receive__2FVb- {
    background-color: seagreen !important;
}

.GerenciarMovimentacoes_motivo__1W3cA {
    cursor: pointer;
    background-color: black;
    color: white;
    width: 1.2em;
    display: inline-block;
    font-weight: bold;
    text-align: center;
    border-radius: 100%;
    font-size: 12px;
    margin-left: 4px;
}
.GerenciarAlimentos_actions__3Bte4 {
    text-align: center;
    width: 10%;
}

.GerenciarAlimentos_actionButton__1tzcR {
    margin-left: 2%;
    margin-right: 2%; 
}
.CadastrarPreparo_tinyActions__xb128 {
    position: relative;
}

.CadastrarPreparo_plusButton__1RnOO {
    position: absolute;
    width: 40%;
    bottom: 0;
    left: 10px;
}

.CadastrarPreparo_minusButton__GQjpj {
    position: absolute;
    width: 40%;
    bottom: 0;
    right: 10px;
}

.CadastrarPreparo_ingredientes__wvKVO {
    padding: 10px;
    border-radius: 10px;
}

.CadastrarPreparo_ingredientes__wvKVO:nth-child(even) {
    background-color: #b8cdc3 !important;
}
/* Table */
.GerenciarPreparos_actions__UYVFr {
    text-align: center;
    width: 15%;
}

.GerenciarPreparos_actionButton__1VKek {
    margin-left: 2%;
    margin-right: 2%; 
}

/* Dynamic Inputs */
.GerenciarPreparos_tinyActions__2PkFe {
    position: relative;
}

.GerenciarPreparos_plusButton__lSDrL {
    position: absolute;
    width: 40%;
    bottom: 0;
    left: 10px;
}

.GerenciarPreparos_minusButton__20c_- {
    position: absolute;
    width: 40%;
    bottom: 0;
    right: 10px;
}
/* Table */
.GerenciarPratos_actions__3kCvR {
    text-align: center;
    width: 15%;
}

.GerenciarPratos_actionButton__3HvyU {
    margin-left: 2%;
    margin-right: 2%; 
}

/* Dynamic Inputs */
.GerenciarPratos_tinyActions__1NTeH {
    position: relative;
}

.GerenciarPratos_plusButton__LxdDK {
    position: absolute;
    width: 40%;
    bottom: 0;
    left: 10px;
}

.GerenciarPratos_minusButton__25886 {
    position: absolute;
    width: 40%;
    bottom: 0;
    right: 10px;
}

.GerenciarPratos_backbutton__lNfwI {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.TimeInput_label__2ASyF {
    border: 2px solid #0071BC;
    color:white !important;
    background-color: #0071BC;
    border-radius: 8px;
    height: 42px;
}

.TimeInput_input__okl91 {
    border: 2px solid #29ABE2;
    border-left: none;
    border-radius: 8px;
    height: 42px;
}

.TimeInput_input__okl91:focus, .TimeInput_input__okl91:active {
    outline: none !important;
    box-shadow: none;
    border-color: #29ABE2;
}
/* .myDiv:first-child {
    border: 2px solid red !important;
    min-width: 100%;
} */

/* .manucu {
    position: relative;
    min-width: 100%;
} */

.CreatableSearchSelect_label__3qkRa {
    border: 2px solid #0071BC;
    color:white !important;
    background-color: #0071BC;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-left: none;
    height: 42px;
    padding: 4px 14px;
    float: left;
    /* display: inline-block; */
}

.CreatableSearchSelect_select__2gGRA {
    /* border: 2px solid #29ABE2 !important; */
    
    /* background-color: white; */
    color: #495057;
    /* padding-left: 10px !important; */
    /* display: inline-block; */
    /* width: auto !important; */
}

.CreatableSearchSelect_select__2gGRA > div {
    /* background-color: white; */
    border: 2px solid #29ABE2;
    /* height: 20px !important; */
    border-left: none !important;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
}

.CreatableSearchSelect_select__2gGRA > div:hover {
    border: 2px solid #29ABE2;
}

.CreatableSearchSelect_select__2gGRA > div:nth-child(3) {
    border: none;
}

/* .select {
    border: 2px solid #29ABE2 !important;
    border-left: none !important;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    background-color: white;
    color: #495057;
    padding-left: 10px !important;
    min-height: 34px !important;
    display: inline-block;
    width: auto !important;
} */

/* .select div:nth-child(4) {
    width: 100%;
} */

.CreatableSearchSelect_select__2gGRA:focus, .CreatableSearchSelect_select__2gGRA:active {
    outline: none !important;
    box-shadow: none !important;
    border-color: #29ABE2 !important;
}
.GerenciarUsuarios_cardHeader__3uV0e {
    cursor: pointer;
}

.GerenciarUsuarios_cardHeader__3uV0e button {
    float: right;
}

.GerenciarUsuarios_actions__3VW6c {
    text-align: center;
    width: 15%;
}

.GerenciarUsuarios_actionButton__1576X {
    margin-left: 2%;
    margin-right: 2%; 
}

.GerenciarUsuarios_listItem__15JK_ button {
    float: right;
}
.CadastrarCardapio_tinyActions__CH5fH {
    position: relative;
}

.CadastrarCardapio_plusButton__1vFtH {
    position: absolute;
    width: 40%;
    bottom: 0;
    left: 10px;
}

.CadastrarCardapio_minusButton__wKaEw {
    position: absolute;
    width: 40%;
    bottom: 0;
    right: 10px;
}
.GerenciarCardapios_actions__2WAT8 {
    text-align: center;
    width: 15%;
}

.GerenciarCardapios_actionButton__2KGXU {
    margin-left: 2%;
    margin-right: 2%; 
}

.GerenciarCardapios_cancel__3QDXW {
    background-color: salmon !important;
}

.GerenciarCardapios_tinyActions__3GmvU {
    position: relative;
}

.GerenciarCardapios_plusButton__1sLjr {
    position: absolute;
    width: 40%;
    bottom: 0;
    left: 10px;
}

.GerenciarCardapios_minusButton__32jnI {
    position: absolute;
    width: 40%;
    bottom: 0;
    right: 10px;
}

.GerenciarCardapios_motivo__T7olZ {
    cursor: pointer;
    background-color: black;
    color: white;
    width: 1.2em;
    display: inline-block;
    font-weight: bold;
    text-align: center;
    border-radius: 100%;
    font-size: 12px;
    margin-left: 4px;
}

.GerenciarCardapios_hide__3F31i {
    display: none;
}
.Calendar_calendar__2tdL8 {
    cursor: pointer;
}

/* Cabeçalho do calendário */
.Calendar_calendar__2tdL8 > div {
    margin-bottom: -4px !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 4px 0 4px;
    background-image: linear-gradient(to right, #0071BC , #29ABE2);
}

/* Nome do mês e ano */
.Calendar_calendar__2tdL8 > div > div > h2 {
    margin-left: 30px !important;
    font-size: 24px;
    color: white;
} 

/* Grupo com os botões de 'Hoje' e controladores */
.Calendar_calendar__2tdL8 > div > div:nth-child(2) {
    margin-right: 30px !important;
}

/* Botão 'Hoje' */
.Calendar_calendar__2tdL8 > div > div:nth-child(2) > button {
    background-color: white !important;
    color: #0071BC !important;
    border-radius: 12px !important;
    margin-right: 16px;
}

/* Botões controladores */
.Calendar_calendar__2tdL8 > div > div:nth-child(2) > div > button {
    background-color: white !important;
    color: black !important;
}

.CardapioCalendario_hide__3_6Kh {
    display: none;
}

.CardapioCalendario_actionModalButton__2lTsf {
    margin-top: 10px;
}

.CardapioCalendario_accordionHeader__1UvrF {
    cursor: pointer;
}

/* DIARIO => #797e84 */
/* SEMANAL => #c14545 */
.CardapioCalendario_legendas__Rtc3g {
    padding: 0px 15px;
    margin-bottom: 30px;
}

.CardapioCalendario_legenda__T49NS {
    width: 20px;
    height: 20px;
    margin-left: 16px;
    border: 1ṕx solid black;
    vertical-align: middle;
    border-radius: 6px;
}

.CardapioCalendario_tituloLegenda__34meQ {
    margin: 0 10px 0 5px;
}

.CardapioCalendario_legendaDiario__1LctY {
    background-color: #29abe2;
}

.CardapioCalendario_legendaSemanal__3tgfP {
    background-color: #c14545;
}
.Card_card__3O7Fp {
    /* vertical-align: middle; */
    padding: 30px 10px;
    background-image: linear-gradient(to right, #0071BC , #29ABE2) !important;
    color: white;
    text-align: center;
    border-color: white;
    cursor: pointer;
}

.Card_card__3O7Fp:active {
    background-image: linear-gradient(to right, #0b4b75 , #028eca) !important;
}

.Card_disabledCard__2owre {
    /* background-image: linear-gradient(to right, #75b9d6 , #99c2d3) !important; */
    opacity: 0.5;
}
.HistoricoExecucaoCardapios_actions__3EaV8 {
    text-align: center;
    width: 15%;
}
.styles_header__3FuD4 {
    width: 100%;
    border: 1px solid black;
    padding: 10px 10px 10px 10px;
}

.styles_header__3FuD4 img {
    width: 56px;
    margin-left: 10px;
}

.styles_escolaData__9Ash7 {
    list-style-type: none;
    margin: 3px 0 0 0;
    padding: 0;
}

.styles_escolaData__9Ash7 li {
    margin-bottom: 6px;
}
.styles_title__3qL9J {
    width: 100%;
    border: 1px solid black;
    text-align: center;
    font-size: 18px;
    padding: 4px 0 4px 0;
    margin-top: 4px;
    margin-bottom: 4px;
}

.styles_detail__2m3nF {
    padding: 4px;
}

.styles_tableItensAquisicao__2s5ml {
    border: 1px solid black;
    width: 100%;
}

.styles_tableItensAquisicao__2s5ml th {
    font-size: 16px;
    text-align:  center;
}

.styles_tableItensAquisicao__2s5ml td {
    font-size: 14px;
    text-align:  center;
}

.styles_tableItensAquisicao__2s5ml .styles_produto__3seN0 {
    text-align:  left;
    padding-left: 12px;
}

.styles_borda__2bjbP {
    width: 100%;
    border: 1px solid black;
    padding: 4px 0 4px 0;
    margin: 10px 10px 4px 10px;
}

.styles_topbuttons__1qrTh {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
/*font-size ainda não definidas*/
/* ----------------------------*/

.styles_detail__3vdJQ {
    padding: 4px;
    font-size: 16px;
}

.styles_tableItens__1iTEo {
    border: 1px solid black;
    width: 100%;
}

.styles_corte__1UmLD {
    border: 1px dashed black;
    margin-top: 15%;
}

.styles_assinaturas__3KcGr {
    margin-top: 100px;
    width: 60%;
}
.styles_assinaturas__3KcGr hr{
    align-items: initial;
}

.styles_assinaturas__3KcGr p{
    font-size: 12px;
    margin-top: -10px;
    text-align: center;
}

.styles_signature__2yYpP{
    margin-top: 44px;
}

.styles_data__3m-rb{
    font-size: 16px;
    position: flex;
    margin-top: 13px;
}

.styles_box__2SWLj{
    margin-top: 15px;
    border: 1px solid black;
    border-radius: 14px;
    width: 100%;
    height: 140px;
}

.styles_box__2SWLj span{
    padding: 15px 10px;
}

.styles_topbuttons__1sfl0 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.styles_tableProdutosExecucao__2Ta6G {
    border: 1px solid black;
    width: 100%;
}

.styles_tableProdutosExecucao__2Ta6G th {
    font-size: 16px;
    text-align:  center;
}

.styles_tableProdutosExecucao__2Ta6G td {
    font-size: 14px;
    text-align:  center;
}

.styles_tableProdutosExecucao__2Ta6G .styles_produto__2kPWz {
    text-align: left;
    padding-left: 12px;
}

.styles_topbuttons__2FNKJ {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.styles_tableProdMovimen__1zk-R {
    width: 100%;
}

.styles_tableProdMovimen__1zk-R th {
    font-size: 16px;
    text-align:  center;
}

.styles_tableProdMovimen__1zk-R td {
    font-size: 14px;
    text-align:  center;
}

.styles_tableProdMovimen__1zk-R .styles_produto__CMqgP {
    text-align:  left;
    padding-left: 12px;
}

.styles_etiqueta__3Mey6 {
    margin: 10px 10px 4px 10px;
}

.styles_assinaturas__19FB_ {
    margin-top: 130px;
    width: 60%;
}
.styles_assinaturas__19FB_ hr{
    align-items: initial;
}

.styles_assinaturas__19FB_ p{
    font-size: small;
    margin-top: -10px;
    text-align: center;
}

.styles_signature__37Ue0{
    margin-top: 34px;
}

.styles_data__3EWUK{
    font-size: 16px;
    position: flex;
    margin-top: 13px;
}

.styles_topbuttons__1lioK {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.styles_borda__1wjAh {
    width: 80%;
    border: 1px solid black;
    text-align: center;
    font-size: 18px;
    padding: 4px 0 4px 0;
    height: 150px;
}

.styles_corte__1re9f {
    border: 1px dashed black;
    margin-top: 15%;
}

.styles_lote__v5sc2 {
    margin-top: 5%;
}
.styles_tableEstoque__3npR_ {
    width: 100%;
    margin-top: 4px;
    margin-bottom: 4px;
}

.styles_tableEstoque__3npR_ th {
    font-size: 16px;
    text-align:  center;
}

.styles_tableEstoque__3npR_ td {
    font-size: 14px;
    text-align:  center;
}

.styles_tableEstoque__3npR_ .styles_produto__27N3A {
    text-align:  left;
    padding-left: 12px;
}

.styles_dados__36LWd {
    width: 100%;
    border: 1px solid black;
    text-align: justify;
    font-size: 18px;
    padding: 4px 0 4px 0;
    margin-top: 4px;
    margin-bottom: 4px;
    padding-left: 25px;
}

.styles_topbuttons__3eMn3 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.styles_dados__1p7BW {
    width: 100%;
    border: 1px solid black;
    text-align: center;
    font-size: 16px;
    margin-top: 4px;
    margin-bottom: 4px;
}

.styles_detalhes__17H0t {
    padding-left: 10px;
    padding-right: 10px;
}

.styles_col__wNzmw {
    padding-left: 0px;
    padding-right: 0px;
}

.styles_tableCalendario__3i6OX {
    width: 100%;
}

.styles_componentes__yzjvo {
    font-weight: bold;
}

.styles_titulo__152kq {
    font-size: 16px;
    font-weight: bold;
    text-align: center !important
}

.styles_cardapio__3MwFe {
    height: 90px; 
    font-size: 12px;
    font-weight: bold;
    text-align: center !important
}

.styles_pratos__3GSPm {
    padding-left: 15px;
    font-size: 16px;
}

.styles_vazio__JD81J {
    font-weight: bold;
    text-align: center !important
}

.styles_topbuttons__2GXJf {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.styles_fornecedor__2U3N7 {
    width: 100%;
    border: 1px solid black;
    text-align: justify;
    font-size: 18px;
    padding: 4px 0 4px 0;
    margin-top: 4px;
    margin-bottom: 4px;
    padding-left: 25px;
}

.styles_topbuttons__Zr1bY {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.styles_table__1axqR {
    border: 1px solid black;
    width: 100%;
}

.styles_tipo__31GoD {
   padding-left: 15px;
}

.styles_titulo__3LnbI {
    text-align: center
}

.styles_topbuttons__34hgm {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.styles_table__3MPvj {
    border: 1px solid black;
    width: 100%;
}

.styles_titulo__2C7oZ {
    text-align: center;
}

.styles_dados__1WgXz {
    padding-left: 15px;
    font-size: 14px;
    font-weight: normal;
}

.styles_perecivel__3IwIH {
    text-align: center;
    font-size: 14px;
    font-weight: normal;
}

.styles_topbuttons__2DMCi {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.styles_table__2ewnm {
    border: 1px solid black;
    width: 100%;
}

.styles_titulo__2JTWc {
    text-align: center;
}

.styles_dados__20wrx {
    padding-left: 15px;
    font-size: 14px;
    font-weight: normal;
}

.styles_vazio__kUWT8 {
    text-align: center;
    font-weight: normal;
}

.styles_topbuttons__3T60l {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.styles_table__o5-45 {
    border: 1px solid black;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 5px;
}

.styles_titulo__2nypf {
    text-align: center;
}

.styles_dados__3ir7v {
    padding-left: 15px;
    font-size: 14px;
    font-weight: normal;
}

.styles_borda__3Nu0p {
    border: 1px solid black;
    text-align: justify;
    padding: 4px 0 4px 0;

    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: 15px;
    margin-right: 15px;

    padding-left: 15px;
}

.styles_nutriente__1yWad {
    font-size: 14px;
    text-align: center;
}   

.styles_topbuttons__1LZS2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.styles_table__Y3U8- {
    border: 1px solid black;
    width: 100%;
}

.styles_tipo__14a9m {
   padding-left: 10px;
   font-size: 14px;
}

.styles_titulo__1FmT1 {
    text-align: center
}

.styles_topbuttons__304Fi {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.styles_table__1_uXg {
    border: 1px solid black;
    width: 100%;
}

.styles_tipo__zpGZw {
   padding-left: 15px;
}

.styles_titulo__3u7RH {
    text-align: center
}

.styles_preparo__3NchV {
    border: 1px solid black;
    text-align: justify;
    padding: 4px 0 4px 0;
    height: 50px;

    margin-left: 0px;
    margin-right: 0px;
    margin-top: 4px;
    margin-bottom: 25px;
}

.styles_topbuttons__1iZDL {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.styles_table__3QM23 {
    border: 1px solid black;
    width: 100%;
    margin-top: 10px;
}

.styles_titulo__1zhoY {
    text-align: center;
}

.styles_dados__3P0fG {
    padding-left: 15px;
    font-size: 14px;
    font-weight: normal;
}

.styles_preparo__ecge0 {
    border: 1px solid black;
    text-align: justify;
    padding: 4px 0 4px 0;

    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 15px;
    margin-right: 15px;

    padding-left: 15px;
    padding-right: 15px;
}

.styles_topbuttons__1XOfQ {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

/*font-size ainda não definidas*/
/* ----------------------------*/

.styles_detail__19Q5L {
    padding: 4px;
    font-size: 16px;
}

.styles_tableItens__3CzbB {
    border: 1px solid black;
    width: 100%;
}

.styles_corte__bI1vE {
    border: 1px dashed black;
    margin-top: 15%;
}

.styles_borda__3aL8J {
    border: 1px solid black;
    text-align: justify;
    padding: 4px 0 4px 0;

    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 15px;
    margin-right: 15px;

    padding-left: 15px;
}

.styles_dados__2jdZ6 {
    text-align: center;
}

.styles_produto__3Jdh7 {
    padding-left: 15px;
}

.styles_lote__1UAIc {
    margin-top: 5%;
    width: 100%;
    border: 1px solid black;
    text-align: center;
    font-size: 18px;
    padding: 4px 0 4px 0;
    height: 150px;
}

.styles_txtLote__KstTn {
    margin-top: 5%;
}

.styles_topbuttons__3qyhb {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.styles_tableBalanco__2soNK {
    border: 1px solid black;
    width: 100%;
}

.styles_tableBalanco__2soNK th {
    font-size: 16px;
    text-align:  center;
}

.styles_tableBalanco__2soNK td {
    font-size: 14px;
    text-align:  center;
}

.styles_topbuttons__2rJRK {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.styles_table__23i2U {
    border: 1px solid black;
    width: 100%;
}

.styles_tipo__23OeD {
   padding-left: 10px;
   font-size: 14px;
}

.styles_titulo__2sDSW {
    text-align: center
}

.styles_topbuttons__3eg0w {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.styles_tableItens__1vZEM {
    border: 1px solid black;
    width: 100%;
}


.styles_assinaturas__3GPCc {
    margin-top: 100px;
    width: 60%;
}
.styles_assinaturas__3GPCc hr{
    align-items: initial;
}

.styles_assinaturas__3GPCc p{
    font-size: 12px;
    margin-top: -10px;
    text-align: center;
}

.styles_signature__IPawC{
    margin-top: 44px;
}
.Notificacao_cardHeader__3D4o2 {
    cursor: pointer;
}

.Notificacao_cardHeader__3D4o2 button {
    float: right;
}

.Notificacao_actions__2UeWg {
    text-align: center;
    width: 15%;
}

.Notificacao_actionButton__FS6Ro {
    margin-right: 2%;
}

.Notificacao_hide__1Ks5W {
    display: none;
}

.Notificacao_notifications__Vq5ml {
    /* padding-right: 0 !important;  */
     /* overflow-y: scroll; */
}

.Notificacao_notifications__Vq5ml ul {
    list-style-type: none;
    margin-top: 6px;
    /* overflow-y: scroll; */
    /* height: 200px; */
    padding: 4px 20px;
    /* margin-right: 48px; */
}

.Notificacao_notifications__Vq5ml li {
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.1);
    background-color: #f0efef;
    font-size: 18px;
    width: 100%;
    padding: 16px 50px;
    margin: 8px 0;
    text-align: justify;
    border-radius: 35px;
}
