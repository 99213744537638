.table {
    border: 1px solid black;
    width: 100%;
    margin-top: 10px;
}

.titulo {
    text-align: center;
}

.dados {
    padding-left: 15px;
    font-size: 14px;
    font-weight: normal;
}

.preparo {
    border: 1px solid black;
    text-align: justify;
    padding: 4px 0 4px 0;

    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 15px;
    margin-right: 15px;

    padding-left: 15px;
    padding-right: 15px;
}

.topbuttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
