.pageTitle {
    color: #006837;
    font-weight: bold;
    font-size: 26px;
    width: 100%;
    margin-bottom: 20px;
}

.customLine {
    border-bottom: 1px solid #29ABE2;
    color: red;
    text-align: right;
    font-size: 11px;
    font-weight: normal;
}