.bloco{
   display: flex;
   width: 100%;
   margin-bottom: 12px;
}
.label {
    border: 2px solid #0071BC;
    color:white !important;
    background-color: #0071BC;
    border-radius: 8px 0px 0px 8px;
    height: 42px;
    
}

.select {
    border: 2px solid #29ABE2;
    border-left: none;
    border-radius: 0px 8px 8px 0px;
    width: 460px;
    height: 42px;
    display: inline-table;
    overflow: visible;
    margin-top: 0px;
    
}

.select:focus, .select:active {
    outline: none !important;
    box-shadow: none;
    border-color: #29ABE2;
}