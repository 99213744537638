.modalTitle {
    color: white !important;
    padding: 4px 40px 4px 40px;
    text-align: center;
    font-weight: bold;
    border: none;
    font-size: 20px;
    border-radius: 20px !important;
    background-image: linear-gradient(to right, #0071BC , #29ABE2) !important;    
}

.modalTitle i {
    margin-right: 10px;
}