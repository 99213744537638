.fa-2x {
    font-size: 2em;
}

.fa {
    position: relative;
    display: table-cell;
    width: 60px;
    height: 36px;
    text-align: center;
    vertical-align: middle;
    font-size: 20px;
}

.main-menu:hover,
nav.main-menu.expanded {
    width: 250px;
    overflow: visible;
    overflow-y: auto;
}

.main-menu {
    padding-top: 70px;
    background: #212121;
    /* border-right: 1px solid #e5e5e5; */
    position: fixed;
    /* top: 25px; */
    bottom: 0;
    height: 100%;
    left: 0;
    width: 60px;
    overflow: hidden;
    -webkit-transition: width .05s linear;
    transition: width .05s linear;
    -webkit-transform: translateZ(0) scale(1, 1);
    z-index: 999;
    float: left;
}

.main-menu>ul {
    margin: 7px 0;
}

.main-menu li, .main-menu div {
    position: relative;
    display: block;
    width: 250px;
}

.subMenuSpace {
    padding-left: 30px;
}

.subSubMenuSpace {
    padding-left: 60px;
}

.main-menu li>a, .main-menu div>a {
    position: relative;
    display: table;
    border-collapse: collapse;
    border-spacing: 0;
    color: #B3B3B3;
    font-family: arial;
    font-size: 14px;
    text-decoration: none;
    -webkit-transform: translateZ(0) scale(1, 1);
    -webkit-transition: all .1s linear;
    transition: all .1s linear;
    margin-bottom: 20px;
}

/* Adaptações para responsividade */
@media (max-width: 767px) {
    .isVisible {
        visibility: visible;
    }

    .isNotVisible {
        visibility: hidden;
    }

    .main-menu {
        padding-top: 20px !important;
        background: #212121;
        position: fixed;
        bottom: 0;
        height: 100%;
        left: 0;
        width: 250px;
        overflow: visible;
        overflow-y: auto;
        z-index: 1001;
        float: left; 
    }

    .main-menu li>a, .main-menu div>a {
        position: relative;
        display: table;
        /* border-collapse: collapse; */
        border-spacing: 0;
        color: #B3B3B3;
        font-family: arial;
        font-size: 14px;
        text-decoration: none;
        -webkit-transform: translateZ(0) scale(1, 1);
        -webkit-transition: all .1s linear;
        transition: all .1s linear;
        margin-bottom: 20px;
    }

    .main-menu:hover,
    nav.main-menu.expanded {
        
    }

    .sidebar-content {
        margin-top: 90px !important;
        margin-left: 0px !important;
    }
}

/* 
.main-menu .nav-icon {
    position: relative;
    display: table-cell;
    width: 60px;
    height: 36px;
    text-align: center;
    vertical-align: middle;
    font-size: 18px;
} */

.main-menu .nav-text {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    width: 190px;
    font-family: 'Titillium Web', sans-serif;
}

.main-menu>ul.logout {
    position: absolute;
    left: 0;
    bottom: 0;
}

.no-touch .scrollable.hover {
    overflow-y: hidden;
}

.no-touch .scrollable.hover:hover {
    overflow-y: auto;
    overflow: visible;
}

/* a:hover,
a:focus {
    text-decoration: none;
} */

nav {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

nav ul,
nav li {
    outline: 0;
    margin: 0;
    padding: 0;
}

.main-menu li:hover>a,
.main-menu div:hover>a,
.no-touch .dashboard-page nav.dashboard-menu ul li:hover a,
.dashboard-page nav.dashboard-menu ul li.active a {
    color: #fff;
    text-decoration: underline;
    /* text-decoration-color: #0071BC; */
}

.sidebar-content {
    margin-top: 55px;
    margin-left: 60px;
    padding: 0 30px 0 30px;
    /* background-color: #808080; */
    /* height: 100% !important; */
    
    
    /* position: absolute;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    overflow-y: scroll;
    z-index: -1; */
}

@media (max-width: 426px) {
    .sidebar-content {
        margin-top: 100px !important;
    }
}