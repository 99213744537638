.tableProdMovimen {
    width: 100%;
}

.tableProdMovimen th {
    font-size: 16px;
    text-align:  center;
}

.tableProdMovimen td {
    font-size: 14px;
    text-align:  center;
}

.tableProdMovimen .produto {
    text-align:  left;
    padding-left: 12px;
}

.etiqueta {
    margin: 10px 10px 4px 10px;
}

.assinaturas {
    margin-top: 130px;
    width: 60%;
}
.assinaturas hr{
    align-items: initial;
}

.assinaturas p{
    font-size: small;
    margin-top: -10px;
    text-align: center;
}

.signature{
    margin-top: 34px;
}

.data{
    font-size: 16px;
    position: flex;
    margin-top: 13px;
}

.topbuttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}