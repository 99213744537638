.flexContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.item {
    color: transparent;
}

.flexContainerUserdatas {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-right: -26px !important;
}

.welcomeMessage {
    color: white;
    opacity: inherit;
    font-size: 12px;
    white-space: nowrap; 
}

.username {
    font-weight: bold;
    color: white;
    white-space: nowrap;
}

.userimage {
    width: 55px;
    height: auto;
}

.userImageContainer {
    width: 60px; 
    height: 60px;
    position: relative;
}

.badge {
    position: absolute;
    top: 0;
    right: 0;
}

.userImageClick {
    z-index: 10;
}

.userPopover {
    margin-top: 0px !important;
    width: 400px;
    background-color: white;
    border: 1px solid #A3A3A3;
    border-radius: 10px;
    padding: 4px 10px;
    left: -133px !important;
    /* top: 60px !important; */
    /* height: 200px; */
    /* margin-right: 20px !important; */
}

.closeButton{
    background: red;
    width: 24px;
    height: 26px;
    text-align: center;
    color: white;
    border-radius: 21px;
    position: absolute;
    right: -13px;
    cursor: pointer;
    top: -14px;
}

@media (max-width: 700px) {
    .flexContainerUserdatas {
        display: none;
        margin-right: -26px !important;
    }

    /* .welcomeMessage {
        font-size: 10px;
    } */
    
    .userimage {
        width: 40px;
        height: auto;
    }   
}

@media (max-width: 426px) {
    .flexContainerUserdatas {
        display: none;
        margin-right: -26px !important;
    }

    /* .welcomeMessage {
        font-size: 10px;
    } */
    
    .userimage {
        width: 40px;
        height: auto;
    }   
}

/* @media (max-width: 384px) {
    .welcomeMessage {
        font-size: 8px;
    }
    
    .userimage {
        width: 35px;
        height: auto;
    }   
}

@media (max-width: 375px) {
    .welcomeMessage {
        font-size: 7px;
    }
    
    .userimage {
        width: 30px;
        height: auto;
    }   
}

@media (max-width: 339px) {
    .welcomeMessage {
        font-size: 8px;
    }
    
    .userimage {
        width: 30px;
        height: auto;
    }  
} */