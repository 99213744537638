.actions {
    text-align: center;
    width: 15%;
}

.actionButton {
    margin-left: 2%;
    margin-right: 2%; 
}

.propostas {
    border-radius: 10px;
    padding: 8px 12px;
    margin-bottom: 6px;
}

.propostas:nth-child(even) {
    background-color: #b8cdc3 !important;
}

hr {
    background-color: #707070 !important;
}

.itens {
    margin-top: 16px;
}

.valorTotalItem {
    font-size: 14px;
    margin-top: -10px !important;
}

.cancel {
    background-color: salmon !important;
}

.motivo {
    cursor: pointer;
    background-color: black;
    color: white;
    width: 1.2em;
    display: inline-block;
    font-weight: bold;
    text-align: center;
    border-radius: 100%;
    font-size: 12px;
    margin-left: 4px;
}

.vigente {
    color: green;
    font-weight: bolder;
}

.cancelada {
    color: red;
    font-weight: bolder;
}