.label {
    border: 2px solid #0071BC;
    color:white !important;
    background-color: #0071BC;
    border-radius: 8px;
    height: 42px;
}

.input {
    border: 2px solid #29ABE2;
    border-left: none;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    height: 42px;
    /* text-transform: uppercase; */
}

.aside {
    height: 34px;
}

.aside > span {
    background-color: transparent !important;
    border: none;
    cursor: pointer;
}

.input:focus, .input:active {
    outline: none !important;
    box-shadow: none;
    border-color: #29ABE2;
}