.table {
    border: 1px solid black;
    width: 100%;
}

.tipo {
   padding-left: 10px;
   font-size: 14px;
}

.titulo {
    text-align: center
}

.topbuttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.tableItens {
    border: 1px solid black;
    width: 100%;
}


.assinaturas {
    margin-top: 100px;
    width: 60%;
}
.assinaturas hr{
    align-items: initial;
}

.assinaturas p{
    font-size: 12px;
    margin-top: -10px;
    text-align: center;
}

.signature{
    margin-top: 44px;
}