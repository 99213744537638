.input {
    height: 44px;
    background: #FFFFFF;
    border: 1px solid #C7C8CC;
    box-sizing: border-box;
    border-radius: 10px;
    margin-bottom: 17px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    color: #4C4C4E;
}

.input::placeholder {
    color: grey;
}