html {
  /* min-height: 100% !important; */
  /* position: relative; */
  /* background-image: url('./assets/images/image-background.png');
  background-repeat: no-repeat !important;
  background-size: 100% 100%; */
}

body {
  margin: 0;
  padding: 0;
  /* background-color: transparent; */
  background-color: #808080;
  /* min-height: 100% !important; */
  font-family: "Aller" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.my-navbar {
  background-color: #006837 !important;
  display: block;
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

@media print {
  body * {
    visibility: hidden;
  }

  .printable, .printable * {
    visibility: visible;
  }

  .hide * {
    visibility: hidden;
    display: none;
  }

  .printable {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
  }
}
.SelectMultiple_select__14Wpm{
  border-left: 2px ridge rgba(17, 153, 216, 0.5) !important;
}
.SelectMultiple_select__14Wpm:focus {
  border-radius: 0px 8px 8px 8px;
  border-left: 2px ridge rgba(17, 153, 216, 0.5) !important;
}