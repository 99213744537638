.simpleModal > div {
    max-width: 400px;
}

/* Modal */
.simpleModal > div > div {
    border-radius: 20px !important;
}

/* Cabeçalho da modal */
.simpleModal > div > div > div:first-child {
    text-align: center !important;
    border: none;
    display: inline-block;
    position: relative;
    height: 0 !important;
    margin-bottom: 28px;
}

/* Titulo da modal */
.simpleModal > div > div > div > div {
    /* border: 2px solid red !important; */
}

/* Botão de fechar modal */
.simpleModal > div > div > div > button {
    position: relative;
    top: -40px;
    /* background-color: #C1272D; */
    /* color: white; */
    /* opacity: 1; */
    /* border-radius: 20px; */
    /* padding: 0; */
    /* border-top: none; */
    /* border: 2px solid red !important; */
}

.simpleModal > div > div > div > button:focus {
    outline: none !important;
}