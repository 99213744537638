.dados {
    width: 100%;
    border: 1px solid black;
    text-align: center;
    font-size: 16px;
    margin-top: 4px;
    margin-bottom: 4px;
}

.detalhes {
    padding-left: 10px;
    padding-right: 10px;
}

.col {
    padding-left: 0px;
    padding-right: 0px;
}

.tableCalendario {
    width: 100%;
}

.componentes {
    font-weight: bold;
}

.titulo {
    font-size: 16px;
    font-weight: bold;
    text-align: center !important
}

.cardapio {
    height: 90px; 
    font-size: 12px;
    font-weight: bold;
    text-align: center !important
}

.pratos {
    padding-left: 15px;
    font-size: 16px;
}

.vazio {
    font-weight: bold;
    text-align: center !important
}

.topbuttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}