.actions {
    text-align: center;
    width: 15%;
}

.actionButton {
    margin-left: 2%;
    margin-right: 2%; 
}

.cancel {
    background-color: salmon !important;
}

.tinyActions {
    position: relative;
}

.plusButton {
    position: absolute;
    width: 40%;
    bottom: 0;
    left: 10px;
}

.minusButton {
    position: absolute;
    width: 40%;
    bottom: 0;
    right: 10px;
}

.motivo {
    cursor: pointer;
    background-color: black;
    color: white;
    width: 1.2em;
    display: inline-block;
    font-weight: bold;
    text-align: center;
    border-radius: 100%;
    font-size: 12px;
    margin-left: 4px;
}

.hide {
    display: none;
}