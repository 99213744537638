.calendar {
    cursor: pointer;
}

/* Cabeçalho do calendário */
.calendar > div {
    margin-bottom: -4px !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 4px 0 4px;
    background-image: linear-gradient(to right, #0071BC , #29ABE2);
}

/* Nome do mês e ano */
.calendar > div > div > h2 {
    margin-left: 30px !important;
    font-size: 24px;
    color: white;
} 

/* Grupo com os botões de 'Hoje' e controladores */
.calendar > div > div:nth-child(2) {
    margin-right: 30px !important;
}

/* Botão 'Hoje' */
.calendar > div > div:nth-child(2) > button {
    background-color: white !important;
    color: #0071BC !important;
    border-radius: 12px !important;
    margin-right: 16px;
}

/* Botões controladores */
.calendar > div > div:nth-child(2) > div > button {
    background-color: white !important;
    color: black !important;
}
