.input {
    border: 2px solid #29ABE2;
    border-radius: 8px;
    height: 42px;
    margin: 4px 0;
    text-align: center;
}

.input:focus, .input:active {
    outline: none !important;
    box-shadow: none;
    border-color: #29ABE2;
}