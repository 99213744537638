/* .myDiv:first-child {
    border: 2px solid red !important;
    min-width: 100%;
} */

/* .manucu {
    position: relative;
    min-width: 100%;
} */

.label {
    border: 2px solid #0071BC;
    color:white !important;
    background-color: #0071BC;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-left: none;
    height: 42px;
    padding: 4px 14px;
    float: left;
    /* display: inline-block; */
}

.select {
    /* border: 2px solid #29ABE2 !important; */
    
    /* background-color: white; */
    color: #495057;
    /* padding-left: 10px !important; */
    /* display: inline-block; */
    /* width: auto !important; */
}

.select > div {
    /* background-color: white; */
    border: 2px solid #29ABE2;
    /* height: 20px !important; */
    border-left: none !important;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
}

.select > div:hover {
    border: 2px solid #29ABE2;
}

.select > div:nth-child(3) {
    border: none;
}

/* .select {
    border: 2px solid #29ABE2 !important;
    border-left: none !important;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    background-color: white;
    color: #495057;
    padding-left: 10px !important;
    min-height: 34px !important;
    display: inline-block;
    width: auto !important;
} */

/* .select div:nth-child(4) {
    width: 100%;
} */

.select:focus, .select:active {
    outline: none !important;
    box-shadow: none !important;
    border-color: #29ABE2 !important;
}