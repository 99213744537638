.cardHeader {
    cursor: pointer;
}

.cardHeader button {
    float: right;
}

.actions {
    text-align: left;
    width: 15%;
}

.actionButton {
    /* margin-left: 2%; */
    margin-right: 2%; 
}

.hide {
    display: none;
}

.listItem button {
    float: right;
}