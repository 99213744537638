.tableItensAquisicao {
    border: 1px solid black;
    width: 100%;
}

.tableItensAquisicao th {
    font-size: 16px;
    text-align:  center;
}

.tableItensAquisicao td {
    font-size: 14px;
    text-align:  center;
}

.tableItensAquisicao .produto {
    text-align:  left;
    padding-left: 12px;
}

.borda {
    width: 100%;
    border: 1px solid black;
    padding: 4px 0 4px 0;
    margin: 10px 10px 4px 10px;
}

.topbuttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}