.tableBalanco {
    border: 1px solid black;
    width: 100%;
}

.tableBalanco th {
    font-size: 16px;
    text-align:  center;
}

.tableBalanco td {
    font-size: 14px;
    text-align:  center;
}

.topbuttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}