.tinyActions {
    position: relative;
}

.plusButton {
    position: absolute;
    width: 40%;
    bottom: 0;
    left: 10px;
}

.minusButton {
    position: absolute;
    width: 40%;
    bottom: 0;
    right: 10px;
}

.ingredientes {
    padding: 10px;
    border-radius: 10px;
}

.ingredientes:nth-child(even) {
    background-color: #b8cdc3 !important;
}