.button {
    color: white !important;
    padding: 0 40px 0 40px;
    text-align: center;
    font-weight: bold;
    border: none;
    font-size: 18px;
    border-radius: 20px !important;
    cursor: pointer;
}

.buttonLogin{
    width: 100%;
    height: 49px;
    background: #101727;
    border: 1px solid #101727;
    box-sizing: border-box;
    border-radius: 10px;
    text-decoration: none;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    display: grid;
    place-items: center;
    font-size: 18px;
    color: #FFFFFF;
    margin-top: 12px;
    cursor: pointer;
}

.button i {
    margin-right: 10px;
}

.button:focus, .button:active {
    outline: none !important;
    box-shadow: none;
    border-color: #29ABE2;
}

.button:disabled {
    /* background-image: linear-gradient(to right, #75b9d6 , #99c2d3) !important; */
    opacity: 0.5;
}

.blueButton {
    background-image: linear-gradient(to right, #0071BC , #29ABE2) !important;
}

.blueButton:active {
    background-image: linear-gradient(to right, #0b4b75 , #028eca) !important;
}

.redButton {
    background-color: #C1272D;
}
.redButton:active {
    background-color: #a00207;
}

.yellowButton {
    background-color: #c4bc25;
}

.yellowButton:active {
    background-color: #c17c27;
}

.greenButton {
    background-color: #39B54A;
}

.greenButton:active {
    background-color: #069219;
}