.loginForm {
    width: 370px;
    display: flex;
    flex-direction: column;
    padding: 20px 27px;
    background: rgba(255, 255, 255, 0.2);
    background-blend-mode: overlay;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    backdrop-filter: blur(50px);
    border-radius: 10px;
}

.blocoLinks{
    width: max-content;
    display: flex;
    gap:6px;
    margin: 10px auto;
}
.nomeEscola{
    line-height: 17px;
    text-align: center;
    margin-bottom: -13px;
}
.nomeEscola>img{
    margin-bottom: 14px;
}
.nomeEscola>p{
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
}

@media (max-width: 940px) {
    .loginForm {
        width: 30%;
    }
}

@media (max-width: 810px) {
    .loginForm {
        width: 36%;
    }
}

@media (max-width: 700px) {
    .loginForm {
        width: 45%;
    }
}

@media (max-width: 600px) {
    .loginForm {
        width: 55%;
    }
}

@media (max-width: 476px) {
    .loginForm {
        width: 65%;
        padding: 15px 30px 30px 30px;
    }
}

.loginForm form {
    margin-top: 20px;
}

.loginTitle {
    font-weight: bold;
    font-size: 24px;
}

p{
    font-size: 1.2rem;
}

p + p {
    margin-top: -10px;
}