header {
    box-shadow: 0px 4px 3px rgba(51,51,51,0.7);
    position: fixed;
    top: 0;
    width: 100% !important;
    z-index: 1000;
    background-image: linear-gradient(to right, #006837 , #009245) !important;
    /* font-family: 'Aller Bold'!important; */
    color:white!important;
    /* text-align: center; */
    padding: 5px 0 0 0;
    /* display: inline-grid; */
}

.menuArea {
    /* height: 100% !impor; */
}

.menuButton {  
    margin: 9px 0 10px 10px;
    background-color: transparent;
    border: 0ch;
    cursor: pointer;
    color: white !important;
    /* text-align: left; */
}

.iconVisibility {
    visibility: hidden;
}

@media (max-width: 767px) {
    .iconVisibility {
        visibility: visible;
    }
}

.headerTitle {
    margin: 5px 0 6px 0;
    text-align: center;
}