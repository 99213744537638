.cardHeader {
    cursor: pointer;
}

.cardHeader button {
    float: right;
}

.actions {
    text-align: center;
    width: 15%;
}

.actionButton {
    margin-right: 2%;
}

.hide {
    display: none;
}