.propostas {
    /* border: 1px solid black; */
    border-radius: 10px;
    padding: 8px 12px;
    margin-bottom: 6px;
}

.propostas:nth-child(even) {
    background-color: #b8cdc3 !important;
}

hr {
    background-color: #707070 !important;
}

.itens {
    margin-top: 16px;
}

.valorTotalItem {
    font-size: 14px;
    margin-top: -10px !important;
}

.tinyActions {
    position: relative;
}

.plusButton {
    position: absolute;
    width: 40%;
    bottom: 0;
    left: 10px;
}

.minusButton {
    position: absolute;
    width: 40%;
    bottom: 0;
    right: 10px;
}

.cardHeader {
    cursor: pointer;
}

.cardHeader button {
    float: right;
}

.actions {
    text-align: left;
    width: 15%;
}

.actionButton {
    /* margin-left: 2%; */
    margin-right: 2%; 
}

.hide {
    display: none;
}