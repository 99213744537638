.card {
    /* vertical-align: middle; */
    padding: 30px 10px;
    background-image: linear-gradient(to right, #0071BC , #29ABE2) !important;
    color: white;
    text-align: center;
    border-color: white;
    cursor: pointer;
}

.card:active {
    background-image: linear-gradient(to right, #0b4b75 , #028eca) !important;
}

.disabledCard {
    /* background-image: linear-gradient(to right, #75b9d6 , #99c2d3) !important; */
    opacity: 0.5;
}