.alert {
    color: white !important;
    padding: 4px 40px 4px 40px;
    text-align: center;
    font-weight: bold;
    border: none;
    font-size: 20px;
    border-radius: 20px !important;
    background-color: #F7931E;
}

.alert i {
    margin-right: 10px;
}