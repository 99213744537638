.cardHeader {
    cursor: pointer;
}

.cardHeader button {
    float: right;
}

.actions {
    text-align: center;
    width: 15%;
}

.actionButton {
    margin-left: 2%;
    margin-right: 2%; 
}

.listItem button {
    float: right;
}