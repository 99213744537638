.hide {
    display: none;
}

.actionModalButton {
    margin-top: 10px;
}

.accordionHeader {
    cursor: pointer;
}

/* DIARIO => #797e84 */
/* SEMANAL => #c14545 */
.legendas {
    padding: 0px 15px;
    margin-bottom: 30px;
}

.legenda {
    width: 20px;
    height: 20px;
    margin-left: 16px;
    border: 1ṕx solid black;
    vertical-align: middle;
    border-radius: 6px;
}

.tituloLegenda {
    margin: 0 10px 0 5px;
}

.legendaDiario {
    background-color: #29abe2;
}

.legendaSemanal {
    background-color: #c14545;
}