.table {
    border: 1px solid black;
    width: 100%;
}

.tipo {
   padding-left: 15px;
}

.titulo {
    text-align: center
}

.topbuttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}