.table {
    /* border-top-left-radius: 10px !important; */
    /* border-top-right-radius: 10px !important; */
}

.table thead {
    text-align: center;
    background-image: linear-gradient(to right, #0071BC , #29ABE2) !important;    
    color: white;
}

.table thead th {
    vertical-align: middle;
    padding-top: 0;
    padding-bottom: 0;
}

.table thead th:first-child {
    border-top-left-radius: 10px;
}  

.table thead th:last-child {
    border-top-right-radius: 10px;
}

.table tbody {
    text-align: center;
    border: none !important;
}

.table tbody tr:nth-child(even) {
    background-color: #b8cdc3;
}

.table tbody td {
    vertical-align: middle !important;
    padding-top: 0;
    padding-bottom: 0;
    border-left: 1px solid #29ABE2;
    border-right: 1px solid #29ABE2;
}

.table tbody td:first-child {
    border-left: none;
}

.table tbody td:last-child {
    border-right: none;
}

