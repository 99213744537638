/* .label {
    border: 2px solid #0071BC;
    color:white !important;
    background-color: #0071BC;
    border-radius: 8px;
    height: 34px;
} */

.file {
    width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.file + label {
    font-size: 1.25em;
    font-weight: 700;
    color: white;
    background-image: linear-gradient(to right, #0071BC , #29ABE2) !important;
    display: inline-block;
    font-size: 18px;
    border-radius: 20px !important;
    padding: 0 40px 0 40px;
}

.file:focus + label,
.file + label:hover {
    background-color: red;
}

.file + label {
	cursor: pointer; /* "hand" cursor */
}

.file + label * {
	pointer-events: none;
}

/* .file {
    border: 2px solid #29ABE2;
    border-left: none;
    border-radius: 8px;
    height: 34px;
}

.file:focus, .file:active {
    outline: none !important;
    box-shadow: none;
    border-color: #29ABE2;
} */