.blocoLink{
    text-align: center;
    margin-bottom: 10px;
}
.blocoLink>a{
    color: #FFF !important;
    cursor: pointer;
    text-decoration: underline !important;
}
.blocoLink>a:hover{
    color: #180000 !important;
    
}