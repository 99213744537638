.label {
    border: 2px solid #0071BC;
    color:white !important;
    background-color: #0071BC;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: none;
    height: 42px;
    padding: 4px 14px;
    /* display: inline-block; */
}

.textarea {
    border: 2px solid #29ABE2 !important;
    border-top: none !important;
    border-bottom-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    background-color: white;
    color: #495057;
    padding-left: 14px !important;
}

.textarea:required {
    box-shadow: none !important;
}

.textarea:focus, .textarea:active {
    outline: none !important;
    box-shadow: none !important;
    border-color: #29ABE2 !important;
}