.header {
    width: 100%;
    border: 1px solid black;
    padding: 10px 10px 10px 10px;
}

.header img {
    width: 56px;
    margin-left: 10px;
}

.escolaData {
    list-style-type: none;
    margin: 3px 0 0 0;
    padding: 0;
}

.escolaData li {
    margin-bottom: 6px;
}