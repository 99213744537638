.mainContent {
    background-color: #E6E6E6;
    border-radius: 10px;
    /* border-top-right-radius: 10px; */
    padding: 20px 50px 20px 50px !important;
    /* height: 100%; */
    /* overflow-y: scroll; */
}

@media (max-width: 530px) {
    .mainContent {
        padding: 20px 10px 20px 10px !important;
    }   
}