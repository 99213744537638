.label {
    border: 2px solid #0071BC;
    color:white !important;
    background-color: #0071BC;
    border-radius: 8px;
    height: 42px;
}

.select {
    border: 2px solid #29ABE2;
    border-left: none;
    border-radius: 8px;
    height: 42px;
}

.select:focus, .select:active {
    outline: none !important;
    box-shadow: none;
    border-color: #29ABE2;
}