.tableEstoque {
    width: 100%;
    margin-top: 4px;
    margin-bottom: 4px;
}

.tableEstoque th {
    font-size: 16px;
    text-align:  center;
}

.tableEstoque td {
    font-size: 14px;
    text-align:  center;
}

.tableEstoque .produto {
    text-align:  left;
    padding-left: 12px;
}

.dados {
    width: 100%;
    border: 1px solid black;
    text-align: justify;
    font-size: 18px;
    padding: 4px 0 4px 0;
    margin-top: 4px;
    margin-bottom: 4px;
    padding-left: 25px;
}

.topbuttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}