/*font-size ainda não definidas*/
/* ----------------------------*/

.detail {
    padding: 4px;
    font-size: 16px;
}

.tableItens {
    border: 1px solid black;
    width: 100%;
}

.corte {
    border: 1px dashed black;
    margin-top: 15%;
}

.borda {
    border: 1px solid black;
    text-align: justify;
    padding: 4px 0 4px 0;

    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 15px;
    margin-right: 15px;

    padding-left: 15px;
}

.dados {
    text-align: center;
}

.produto {
    padding-left: 15px;
}

.lote {
    margin-top: 5%;
    width: 100%;
    border: 1px solid black;
    text-align: center;
    font-size: 18px;
    padding: 4px 0 4px 0;
    height: 150px;
}

.txtLote {
    margin-top: 5%;
}

.topbuttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}