.actionButton {
    text-align: center;
    padding: 4px 8px;
    border-radius: 10px;
    background-color: #E6E6E6;
    box-shadow: none !important;
    border: 1px solid #29ABE2;
    margin: 2% 2%;
    cursor: pointer;
}

.actionButton:active {
    background-color: #cecdcd;
}

.actionButton:disabled {
    cursor: not-allowed;
}

.actionButton:focus, .actionButton:active {
    outline: none !important;
    box-shadow: none;
    border-color: #29ABE2;
}