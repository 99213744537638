.tableProdutosExecucao {
    border: 1px solid black;
    width: 100%;
}

.tableProdutosExecucao th {
    font-size: 16px;
    text-align:  center;
}

.tableProdutosExecucao td {
    font-size: 14px;
    text-align:  center;
}

.tableProdutosExecucao .produto {
    text-align: left;
    padding-left: 12px;
}

.topbuttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}