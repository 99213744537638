.subheader {
    padding: 0 32px 0 35px;
}

@media (max-width: 478px) {
    .subheader {
        padding: 0 0 0 15px;
    }   
}

@media (max-width: 426px) {
    .subheader {
        padding: 0 15px 0 15px;
    }   
}

.leftItens {
    text-align: left;
}

.rightItens {
    text-align: right;
}