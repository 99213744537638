.homeItemOverlayPopoverOver {
    margin-top: 0px !important;
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    z-index: 10000;
}

.homeItemOverlayPopover {
    margin-top: 0px !important;
    background-color: rgba(0, 0, 0, 0);
    border: 0;
}