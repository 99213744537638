.table {
    border: 1px solid black;
    width: 100%;
}

.titulo {
    text-align: center;
}

.dados {
    padding-left: 15px;
    font-size: 14px;
    font-weight: normal;
}

.perecivel {
    text-align: center;
    font-size: 14px;
    font-weight: normal;
}

.topbuttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}