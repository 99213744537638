.flexContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.item {
    color: transparent;
}

.logo {
    width: 100px;
    height: auto;
}

@media (max-width: 426px) {
    .logo {
        width: 80px;
        height: auto;
    }    
}

/* @media (max-width: 384px) {
    .logo {
        width: 75px;
        height: auto;
    } 
}

@media (max-width: 375px) {
    .logo {
        width: 70px;
        height: auto;
    }   
}

@media (max-width: 339px) {
    .logo {
        width: 60px;
        height: auto;
    }
} */