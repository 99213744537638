.homeItem {
    cursor: pointer;
    margin-bottom: 80px;
}

.homeItem div {
    border: 1px solid #0071BC;
    font-size: 18px;
    font-weight: normal;
    border-radius: 30px;
    padding: 4px 40px 4px 40px;
    margin-top: 20px;
    min-width: auto !important;
    display: inline-block;
    color: #0071BC;
}