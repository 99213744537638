.notifications {
    /* padding-right: 0 !important; */
    /* overflow-y: scroll; */
}

.notifications ul {
    list-style-type: none;
    padding: 0;
    margin-top: 6px;
    overflow-y: scroll;
    height: 300px;
    padding-right: 8px;
    margin-right: -48px;
}

.notifications li {
    /* border: 1px solid rgba(0, 0, 0, 0.1); */
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.1);
    background-color: #f0efef;
    font-size: 14px;
    width: 100%;
    padding: 6px 10px 6px 10px;
    margin: 8px 0;
    text-align: justify;
}