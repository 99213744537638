/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
    font-family: 'Aller';
    font-style: normal;
    font-weight: normal;
    src: local('Aller'), url('Aller_Rg.woff') format('woff');
}

@font-face {
    font-family: 'Aller-Light';
    font-style: normal;
    font-weight: normal;
    src: local('Aller-Light'), url('Aller_Light.woff') format('woff');
}

@font-face {
    font-family: 'Aller Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Aller Italic'), url('Aller_It.woff') format('woff');
}


@font-face {
    font-family: 'Aller Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Aller Bold'), url('Aller_Bd.woff') format('woff');
}


@font-face {
    font-family: 'Aller Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Aller Bold Italic'), url('Aller_BdIt.woff') format('woff');
}


@font-face {
font-family: 'Aller Display';
font-style: normal;
font-weight: normal;
src: local('Aller Display'), url('AllerDisplay.woff') format('woff');
}


@font-face {
font-family: 'Aller Light Italic';
font-style: normal;
font-weight: normal;
src: local('Aller Light Italic'), url('Aller_LtIt.woff') format('woff');
}