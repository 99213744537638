.subItem {
    margin: 5px 0 5px 0;
    /* opacity: 1 !important; */
}

.subItem * {
    display: inline-block;
}

.subItem a {
    text-decoration: none;
    border-radius: 10px;
    border: 1px solid white;
    padding: 2px 20px 0 20px;
    background-color: white;
    box-shadow: 3px 3px rgba(128, 128, 128, 0.2);
}

/* .subItem a:hover {
    border-color: 
} */

.iconSubmenu {
    margin-right: 5px;
}

.nameSubmenu {
    margin-left: 5px;
    color: #666666;
}